import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { PageContainer } from 'features/auth/components'
import usePageActions from 'features/hooks/usePageActions'
import AddAccounts from 'pages/onboarding/steps/AddAccounts'
import AddPermissions from 'pages/onboarding/steps/AddPermissions'
import MasterTimeBlockingStep, { OPTIONS as MODES } from 'pages/onboarding/steps/MasterTimeBlocking'
import MultiCalendarPrompt from 'pages/onboarding/steps/MultiCalendarPrompt'
import Survey from 'pages/onboarding/steps/Survey'
import SelectSync from 'pages/onboarding/steps/SelectSync'
import ShowCalendar from 'pages/onboarding/steps/ShowCalendar'
import AddTasks from 'pages/onboarding/steps/AddTasks'
import { getMainAccount } from 'store/accounts/selectors'

export const fromOnboarding = 'fromOnboarding'
export const totalSteps = 5
export const showFocusBlockStep = 4

const surveyStepId = 'survey'
const addPermissionsStepId = 'addPermissions'
const multiCalendarPromptStepId = 'multiCalendarPrompt'
const calendarStepId = 'calendar'
const addTasksStepId = 'addTasks'
const addAccountsStepId = 'addAccounts'
const selectSyncStepId = 'selectSync'
const masterTimeBlockingStepId = 'masterTimeBlocking'

const normalFlowStepMap = {
  [surveyStepId]: 0,
  [addPermissionsStepId]: 1,
  [multiCalendarPromptStepId]: 2,
  [selectSyncStepId]: 3,
  [addAccountsStepId]: 4,
  [masterTimeBlockingStepId]: 5,
  [calendarStepId]: 6,
}

const multicalendarFlowStepMap = {
  [surveyStepId]: 0,
  [addPermissionsStepId]: 1,
  [selectSyncStepId]: 2,
  [addAccountsStepId]: 3,
  [masterTimeBlockingStepId]: 4,
  [calendarStepId]: 5,
}

export default function Onboarding() {
  const history = useHistory()
  const location = useLocation()
  const { createInlineTask, deleteTask, findItemById, saveTask } = usePageActions()
  const mainAccount = useSelector((state) => getMainAccount(state))
  const session = useSelector((state) => state.session)

  const user = session.user

  const [calendarMode, setCalendarMode] = useState(null)
  const [createdSprintData] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [renderSettings, setRenderSettings] = useState({ canRender: false, multicalendarFlow: false })
  const [syncOptionSelected, setSyncOptionSelected] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const pageSettings = { canRender: true }
    const searchParamsRegEx = /(\?|&)(?<param>.+?)=(?<value>([^&]*)?)/g
    const searchParams = {}

    let currentMatch
    while ((currentMatch = searchParamsRegEx.exec(queryString)) !== null) {
      searchParams[currentMatch.groups.param] = currentMatch.groups.value
    }

    if (searchParams.multicalendarsync === 'true') {
      pageSettings.multicalendarFlow = true
    }

    setRenderSettings(pageSettings)
  }, [])

  useEffect(() => {
    const fromMulticalendarOnboarding = location.state?.fromMulticalendarOnboarding
    const nextStep = location.state?.currentStep
    const onboardingPath = location.state?.onboardingPath

    if (onboardingPath === 'multicalendarsync') {
      setRenderSettings({ canRender: true, multicalendarFlow: true })
      return
    }

    if (fromMulticalendarOnboarding && nextStep) {
      setRenderSettings({ canRender: true, multicalendarFlow: true })
      setCurrentStep(nextStep)
      return
    }
  }, [location])

  const getStepNumberForId = (stepId) => {
    const steps = renderSettings.multicalendarFlow ? multicalendarFlowStepMap : normalFlowStepMap
    return steps[stepId]
  }

  const handleStepDone = (stepNumber, ...args) => {
    if (stepNumber === getStepNumberForId(surveyStepId)) {
      const areCredentialsValid = !mainAccount?.areCredentialsInvalid && !mainAccount?.missingScopes
      const nextStep = areCredentialsValid
        ? getStepNumberForId(addPermissionsStepId) + 1
        : getStepNumberForId(addPermissionsStepId)
      setCurrentStep(nextStep)
      return
    }

    if (stepNumber === getStepNumberForId(addPermissionsStepId)) {
      setCurrentStep(++stepNumber)
      return
    }

    if (stepNumber === getStepNumberForId(multiCalendarPromptStepId)) {
      const shouldGoToSyncStep = args[0]
      const nextStep = shouldGoToSyncStep
        ? getStepNumberForId(selectSyncStepId)
        : getStepNumberForId(masterTimeBlockingStepId)
      setCurrentStep(nextStep)
      return
    }

    if (stepNumber === getStepNumberForId(selectSyncStepId)) {
      const syncOption = args[0]

      if (syncOption) {
        setSyncOptionSelected(syncOption)
        setCurrentStep(++stepNumber)
      } else {
        const nextStep = getStepNumberForId(masterTimeBlockingStepId)
        setCurrentStep(nextStep)
      }

      return
    }

    if (stepNumber === getStepNumberForId(addAccountsStepId)) {
      setCurrentStep(getStepNumberForId(masterTimeBlockingStepId))
      return
    }

    if (stepNumber === getStepNumberForId(masterTimeBlockingStepId)) {
      const topicSelected = args[0]
      setCalendarMode(topicSelected)
      setCurrentStep(getStepNumberForId(calendarStepId))
      return
    }

    if (stepNumber === getStepNumberForId(calendarStepId)) {
      if (calendarMode === MODES.TASK) {
        const { createdTask, shouldCreateSprint } = args[0]

        if (shouldCreateSprint) {
          setCalendarMode(MODES.SPRINT)
        } else {
          const onboardingState = { currentStep: 4, fromOnboarding: true, onboardingTask: createdTask }
          history.push('/', onboardingState)
        }

        return
      } else if (calendarMode === MODES.SPRINT) {
        const { sprint } = args[0]
        const onboardingState = { currentStep: 4, fromOnboarding: true, onboardingSprint: sprint }
        history.push('/', onboardingState)
        return
      }
    }
  }

  const renderSurveyStep = () => (
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} key={'survey'}>
      <Survey onStepDone={handleStepDone} stepNumber={getStepNumberForId(surveyStepId)} user={user} />
    </motion.div>
  )

  const renderCalendarStep = () => (
    <FullHeightStepContainer
      animate={{ opacity: 1, transition: { delay: 1 } }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={'calendar'}>
      <ShowCalendar
        mode={calendarMode}
        onCreateTask={createInlineTask}
        onDeleteTask={deleteTask}
        onSaveTask={saveTask}
        onStepDone={handleStepDone}
        stepNumber={getStepNumberForId(calendarStepId)}
        user={user}
      />
    </FullHeightStepContainer>
  )

  const renderAddTaskStep = () => (
    <FullHeightStepContainer
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, y: 25 }}
      key={'addTask'}>
      <AddTasks
        findItemById={findItemById}
        onCreateTask={createInlineTask}
        onDeleteTask={deleteTask}
        onSaveTask={saveTask}
        onStepDone={handleStepDone}
        session={session}
        sprintData={createdSprintData}
        stepNumber={getStepNumberForId(addTasksStepId)}
      />
    </FullHeightStepContainer>
  )

  const renderAddAccountsStep = () => (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={'addAccounts'}
      style={{ minHeight: '50%', width: '100%' }}>
      <AddAccounts
        onStepDone={handleStepDone}
        stepNumber={getStepNumberForId(addAccountsStepId)}
        syncOptionSelected={syncOptionSelected}
      />
    </motion.div>
  )

  const renderSelectSyncStep = () => (
    <FullHeightStepContainer animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} key={'selectSync'}>
      <SelectSync onStepDone={handleStepDone} stepNumber={getStepNumberForId(selectSyncStepId)} />
    </FullHeightStepContainer>
  )

  const renderMultiCalendarPromptStep = () => (
    <FullHeightStepContainer
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={'multiCalendarPrompt'}>
      <MultiCalendarPrompt onStepDone={handleStepDone} stepNumber={getStepNumberForId(multiCalendarPromptStepId)} />
    </FullHeightStepContainer>
  )

  const renderMasterTimeBlockingStep = () => (
    <FullHeightStepContainer
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={'masterTimeBlocking'}>
      <MasterTimeBlockingStep onStepDone={handleStepDone} stepNumber={getStepNumberForId(masterTimeBlockingStepId)} />
    </FullHeightStepContainer>
  )

  const renderAddPermissionsStep = () => (
    <FullHeightStepContainer
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      key={'addPermissions'}>
      <AddPermissions
        account={mainAccount}
        onStepDone={handleStepDone}
        stepNumber={getStepNumberForId(addPermissionsStepId)}
      />
    </FullHeightStepContainer>
  )

  const renderCurrentStep = () => {
    switch (currentStep) {
      case getStepNumberForId(surveyStepId): {
        return renderSurveyStep()
      }

      case getStepNumberForId(addPermissionsStepId): {
        return renderAddPermissionsStep()
      }

      case getStepNumberForId(calendarStepId): {
        return renderCalendarStep()
      }

      case getStepNumberForId(addTasksStepId): {
        return renderAddTaskStep()
      }

      case getStepNumberForId(addAccountsStepId): {
        return renderAddAccountsStep()
      }

      case getStepNumberForId(selectSyncStepId): {
        return renderSelectSyncStep()
      }

      case getStepNumberForId(multiCalendarPromptStepId): {
        return renderMultiCalendarPromptStep()
      }

      case getStepNumberForId(masterTimeBlockingStepId): {
        return renderMasterTimeBlockingStep()
      }

      default: {
        return null
      }
    }
  }

  return (
    <RootContainer>
      <PageContainer>
        <AnimatePresence exitBeforeEnter initial={false}>
          {renderSettings.canRender && renderCurrentStep()}
        </AnimatePresence>
      </PageContainer>
    </RootContainer>
  )
}

const RootContainer = styled.div`
  height: 100vh;
  overflow: auto;
  position: relative;
  width: 100vw;
`

RootContainer.displayName = 'RootContainer'

const FocusedLineContainer = styled(motion.div)`
  background: white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

FocusedLineContainer.displayName = 'FocusedLineContainer'

const FullHeightStepContainer = styled(motion.div)`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  width: 100%;
`

FullHeightStepContainer.displayName = 'FullHeightStepContainer'
