import React, { PureComponent } from 'react'
import container from './container'

import EditingLine from 'EditingLine'
import LineView from 'LineView'

import propTypes from './propTypes'
import CreatingLine from 'CreatingLine'
import { translations } from 'gipsy-misc'

class Line extends PureComponent {
  static propTypes = {
    ...propTypes,
  }
  static defaultProps = {
    lineThrough: false,
    keepJustCompleted: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      startedCreation: false,
      startedEdition: false,
      editing: false,
    }
  }

  componentDidMount() {
    if (this.props.startEdition) {
      this.startEdition()
    }
    if (this.props.startCreation) {
      this.startCreation()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const hasStartCreationChangedBeenEnabled = !prevProps.startCreation && this.props.startCreation
    if (hasStartCreationChangedBeenEnabled && !this.state.startedCreation) {
      this.startCreation()
    }

    if (prevProps.disableEdit && this.state.editing) {
      this.editStop()
    }
    if (!prevProps.startEdition && this.props.startEdition && !this.state.startedEdition) {
      this.startEdition()
    }
  }

  checkIfEditIsDisabled = () => {
    const { session, isCreating, disableEdit, item } = this.props
    const focusedTaskId = session && session.focusSession && session.focusSession.taskId
    const isTaskOnFocusSession = focusedTaskId && focusedTaskId === item.id
    return isTaskOnFocusSession || (!isCreating && disableEdit)
  }

  editStart = () => {
    const { item, onEditStart } = this.props
    const isEditDisabled = this.checkIfEditIsDisabled()
    if (isEditDisabled) {
      return
    }
    onEditStart?.({ item })

    this.setState({
      editing: true,
    })
  }

  setEditing = (editing) => {
    this.setState({ editing })
  }

  editStop = () => {
    this.setState({
      editing: false,
    })
  }

  startCreation = () => {
    this.setState({
      startedCreation: true,
    })
    this.editStart()
    this.props.onStartCreation?.()
  }

  onSave = async (...args) => {
    this.props.onSave(...args)
    this.editStop()
  }

  startEditing = () => {
    this.setState({ editing: true })
  }

  startEdition = () => {
    this.setState({
      startedEdition: true,
      editing: true,
    })
  }

  onCancel = () => {
    this.setState({
      creating: false,
      startedCreation: false,
    })
    this.props.onCancel?.()
  }

  onCancelEdit = () => {
    this.editStop()
    this.props.onCancelEdit?.()
  }

  onCreate = (task, ...args) => {
    let createdTask = task
    const sprint = this.props.sprint

    if (sprint) {
      createdTask = {
        ...task,
        sprintInfo: {
          estimatedTime: sprint.estimatedTime,
          id: sprint.id,
          pin: sprint.pin,
          title: sprint.title,
        },
        when: { date: '', rank: 0 },
      }
    }

    this.props.onCreate(createdTask, ...args)
  }

  clearHighlightedEvent = () => {
    const { setHighlightedEventId } = this.props
    setHighlightedEventId && setHighlightedEventId(null)
  }

  render() {
    const {
      alignActionsPopupToContainer,
      calendarEventInfoLeft,
      componentSource,
      displayTimeOnlyForEventInfo,
      session,
      item,
      onChange,
      onDelete,
      isCreating,
      isDraggable,
      onMoveToTop,
      onScheduleToToday,
      onPauseFocus,
      onArchive,
      isArchivable,
      hideWhenDate,
      hideMoreActions,
      hideDateInput,
      hideDragIcon,
      hideSprint,
      innerLeftPadding,
      innerRightPadding,
      renderCreateBottomButtons,
      renderEditBottomButtons,
      draggableProps,
      dragHandleProps,
      dragIconLeftPosition,
      draggableStyle,
      isDragging,
      lineThrough,
      showCalendarEventInfo,
      inChromeExtension,
      allProjects,
      activeSprintsMap,
      activeTags,
      disableHover,
      disableEdit,
      disableLinks,
      noMargin,
      innerRef,
      onClickLeftArrow,
      onClickRightArrow,
      onRemoveFromSprint,
      done,
      onComplete,
      animateComplete,
      keepJustCompleted,
      onStartFocus,
      openUrls,
      isSprintTask,
      ignoreOutsideClicks,
      showSprintInfo,
      color,
      playIconBackground,
      grayCheckBox,
      startSprintCreation,
      readOnlyDate,
      onTogglePin,
      canBlockToCalendar,
      hideBlockToCalendarOption,
      hideCompleteCheckbox,
      hideCreateButton,
      isCalendarDraggable,
      marginBottom,
      onDeleteFocusSession,
      onClickFocusSession,
      onUpdateFocusSession,
      showSnackbar,
      hideStartButton,
      marginAfterLine,
      registerShortcuts,
      unregisterShortcuts,
      onStartFsAndCreate,
      onTitleChange,
      hideMiddleRow,
      shouldFocusEditableTitle,
      when,
      pin,
      urlInfo,
      project,
      tag,
      title,
      estimatedTime,
      hideCreationLinePlaceholder,
      hideScheduleSection,
      verticalView,
      selected,
      selectMode,
      onSelect,
    } = this.props

    const { startedCreation, editing, creating } = this.state

    const isEditDisabled = this.checkIfEditIsDisabled()

    if (isCreating && !startedCreation && !hideCreationLinePlaceholder) {
      return (
        <CreatingLine
          componentSource={componentSource}
          onClick={this.startCreation}
          marginLeft={innerLeftPadding}
          marginRight={innerRightPadding}
          icon={'SurroundedSquarePlus'}
          fillIcon={'false'}
          activeSprintsMap={activeSprintsMap}
          label={translations.inlineAddTask.addTask}
        />
      )
    }
    if (creating || editing) {
      return (
        <EditingLine
          componentSource={componentSource}
          item={item}
          innerRef={innerRef}
          isSprintTask={isSprintTask}
          canBlockToCalendar={canBlockToCalendar}
          hideBlockToCalendarOption={hideBlockToCalendarOption}
          hideCreateButton={hideCreateButton}
          hideDateInput={hideDateInput}
          hideScheduleSection={hideScheduleSection}
          verticalView={verticalView}
          onTitleChange={this.onTitleChange}
          isCreating={isCreating}
          onSave={this.onSave}
          onCreate={this.onCreate}
          onCancel={this.onCancel}
          onCancelEdit={this.onCancelEdit}
          onDelete={onDelete}
          readOnlyDate={readOnlyDate}
          startSprintCreation={startSprintCreation}
          onChange={onChange}
          onTogglePin={onTogglePin}
          marginLeft={innerLeftPadding}
          marginRight={innerRightPadding}
          marginBottom={marginBottom}
          renderCreateBottomButtons={renderCreateBottomButtons}
          renderEditBottomButtons={renderEditBottomButtons}
          allProjects={allProjects}
          activeSprintsMap={activeSprintsMap}
          activeTags={activeTags}
          ignoreOutsideClicks={ignoreOutsideClicks}
          hideSprint={hideSprint}
          session={session}
          inChromeExtension={inChromeExtension}
          onClickFocusSession={onClickFocusSession}
          onDeleteFocusSession={onDeleteFocusSession}
          onUpdateFocusSession={onUpdateFocusSession}
          showSnackbar={showSnackbar}
          clearHighLightedEvent={this.clearHighlightedEvent}
          registerShortcuts={registerShortcuts}
          unregisterShortcuts={unregisterShortcuts}
          onStartFsAndCreate={onStartFsAndCreate}
          when={when}
          pin={pin}
          urlInfo={urlInfo}
          project={project}
          tag={tag}
          title={title}
          estimatedTime={estimatedTime}
        />
      )
    }

    return (
      <LineView
        alignActionsPopupToContainer={alignActionsPopupToContainer}
        calendarEventInfoLeft={calendarEventInfoLeft}
        componentSource={componentSource}
        displayTimeOnlyForEventInfo={displayTimeOnlyForEventInfo}
        item={item}
        session={session}
        done={done}
        color={color}
        hideStartButton={hideStartButton}
        isCalendarDraggable={isCalendarDraggable}
        grayCheckBox={grayCheckBox}
        registerShortcuts={registerShortcuts}
        unregisterShortcuts={unregisterShortcuts}
        playIconBackground={playIconBackground}
        innerRef={innerRef}
        draggableProps={draggableProps}
        dragHandleProps={dragHandleProps}
        dragIconLeftPosition={dragIconLeftPosition}
        draggableStyle={draggableStyle}
        isDragging={isDragging}
        innerLeftPadding={innerLeftPadding}
        innerRightPadding={innerRightPadding}
        isDraggable={isDraggable}
        showCalendarEventInfo={showCalendarEventInfo}
        inChromeExtension={inChromeExtension}
        noMargin={noMargin}
        disableHover={disableHover}
        disableEdit={disableEdit}
        disableLinks={disableLinks}
        creating={isCreating}
        hideCompleteCheckbox={hideCompleteCheckbox}
        hideWhenDate={hideWhenDate}
        hideMoreActions={hideMoreActions}
        hideDragIcon={hideDragIcon}
        lineThrough={lineThrough}
        onPauseFocus={onPauseFocus}
        onMoveToTop={onMoveToTop}
        isArchivable={isArchivable}
        animateComplete={animateComplete}
        keepJustCompleted={keepJustCompleted}
        onStartFocus={onStartFocus}
        openUrls={openUrls}
        onArchive={onArchive}
        onDelete={onDelete}
        onScheduleToToday={onScheduleToToday}
        onComplete={onComplete}
        editStart={this.editStart}
        isEditDisabled={isEditDisabled}
        setEditing={this.setEditing}
        marginAfterLine={marginAfterLine}
        onClickLeftArrow={onClickLeftArrow}
        onClickRightArrow={onClickRightArrow}
        editing={editing}
        shouldFocusEditableTitle={shouldFocusEditableTitle}
        showSnackbar={showSnackbar}
        showSprintInfo={showSprintInfo}
        onClickFocusSession={onClickFocusSession}
        onDeleteFocusSession={onDeleteFocusSession}
        onUpdateFocusSession={onUpdateFocusSession}
        ignoreOutsideClicks={ignoreOutsideClicks}
        clearHighLightedEvent={this.clearHighlightedEvent}
        onRemoveFromSprint={onRemoveFromSprint}
        onTitleChange={onTitleChange}
        hideMiddleRow={hideMiddleRow}
        selected={selected}
        selectMode={selectMode}
        onSelect={onSelect}
      />
    )
  }
}

export default container(Line)
