import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { produce } from 'immer'
import moment from 'moment'
import throttle from 'lodash/throttle'
import set from 'lodash/set'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import Footer from './components/Footer'
import ComposerContainer from './components/ComposerContainer'
import Recurrency from './components/Recurrency'
import TitleSection from './components/TitleSection'
import container from './container'

import { models, styles, translations, utils } from 'gipsy-misc'
import { recurrenceComponentsUtils, Separator, TimeRange } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import DatePickerInput from 'features/calendar/components/CalendarPanel/components/DatePickerInput'
import usePageActions from 'features/hooks/usePageActions'
import HomebaseLine from 'features/list/components/line'
import { sprintDraggableType } from 'features/taskPanel/components/DragDropContext'
import { getItemDurationInMinutes } from 'logic/calendar'

const { DailyScheduleOptions, InstanceOptions, Schedules } = models.recurrency
const componentName = 'SprintComposer'

export const droppableId = 'sprintComposerTasks'
export const droppableType = 'sprint-composer'
export const sprintComposerPortalId = 'sprint-composer-portal'
const { taskPanelContainerWidth } = variables

function useShownChanged(isShown) {
  const isShownRef = useRef(false)
  const hasChanged = isShownRef.current !== isShown
  if (hasChanged) isShownRef.current = isShown
  return hasChanged
}

function SprintComposer(props) {
  const {
    findItemById,
    handleTaskSave,
    onClickDelete,
    createInlineTask,
    completeTask,
    recurringItemPopup,
  } = usePageActions()

  const pointerLeftPosition = useRef(0)
  const titleInputRef = useRef(null)

  const [title, setTitle] = useState('')
  const [isEmptyTitleErrorShown, setEmptyTitleErrorShown] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedRecurrency, setSelectedRecurrency] = useState({
    customSettings: null,
    every: 1,
    schedule: Schedules.Weekly,
    type: DailyScheduleOptions.NoRepeat,
  })
  const [startTime, setStartTime] = useState(moment())
  const [estimatedTime, setEstimatedTime] = useState(
    utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
  )

  const {
    session,
    editingSprint,
    creatingSprint,
    onCreate,
    onCancel,
    onEdit,
    onDelete,
    isShown,
    addedTasks,
    customCancelButton,
    enterDelay,
    onCurrentSprintChange,
    registerShortcuts,
    unregisterShortcuts,
    updateCalendarDate,
  } = props

  const {
    addFilteredId,
    addTaskToSprint,
    addTmpItemToTaskPanel,
    clearTmpItemsFromTaskPanel,
    removeFilteredId,
    removeTaskFromSprint,
    replaceFilteredId,
    resetFilteredIds,
    setSelectModeInTaskPanel,
    setTasksFromSprint,
    updateTaskFromSprint,
  } = props.actions

  const hasShownChanged = useShownChanged(isShown)
  const isEditingMode = !!editingSprint

  useEffect(() => {
    const savePositions = (e) => {
      if (e.touches) {
        pointerLeftPosition.current = e.touches[0].pageX
      } else {
        pointerLeftPosition.current = e.pageX
      }
    }

    window.addEventListener('mousedown', savePositions)
    window.addEventListener('touchstart', savePositions)

    return () => {
      window.removeEventListener('mousedown', savePositions)
      window.removeEventListener('touchstart ', savePositions)
    }
  }, [])

  useEffect(() => {
    if (isShown && props.selectedSlot) {
      const { start, end } = props.selectedSlot
      const startTime = moment(start)
      const endTime = moment(end)
      const diff = endTime.diff(startTime, 'minutes')
      const estimatedTime = utils.datetime.convertMinuteToNanoseconds(diff)
      setStartTime(startTime)
      setEstimatedTime(estimatedTime)
      updateCalendarDate(new Date(start))
    }
  }, [props.selectedSlot, isShown, updateCalendarDate])

  const clearState = useCallback(() => {
    setTasksFromSprint([])
    resetFilteredIds()
    clearTmpItemsFromTaskPanel()
    setTitle('')
    setEmptyTitleErrorShown(false)
    setSelectedRecurrency({
      customSettings: null,
      every: 1,
      schedule: Schedules.Weekly,
      type: DailyScheduleOptions.NoRepeat,
    })
  }, [setTasksFromSprint, resetFilteredIds, clearTmpItemsFromTaskPanel])

  const remapTasksWithSprintData = useCallback(
    (tasks) => {
      const pin = {
        time: startTime.format(),
      }
      return tasks.map((task) => {
        task.when = { date: '', rank: 0 }
        if (!task.sprintInfo) {
          task.sprintInfo = {
            estimatedTime,
            title,
            pin,
            id: editingSprint ? editingSprint.id : '',
          }
        }
        return task
      })
    },
    [title, estimatedTime, startTime, editingSprint]
  )

  const addedTasksList = useMemo(() => {
    return remapTasksWithSprintData(JSON.parse(JSON.stringify(addedTasks)))
  }, [addedTasks, remapTasksWithSprintData])

  const buildSprintFromState = useCallback(() => {
    const when = {
      date: startTime.format('YYYY-MM-DD'),
    }
    const pin = {
      time: startTime.format(),
    }

    const tasks = addedTasksList
    const sprint = {
      id: editingSprint ? editingSprint.id : '',
      title,
      estimatedTime,
      tasks,
      tasksId: tasks ? tasks.map((task) => task.id) : [],
      pin,
      when,
      type: models.item.type.SPRINT,
    }

    const recurrencyDetails = recurrenceComponentsUtils.getRecurrencyDetails({
      customTypeSettings: selectedRecurrency.customSettings,
      every: selectedRecurrency.every,
      recurrencyType: selectedRecurrency.type,
      sprintStartTime: startTime,
    })

    if (recurrencyDetails) {
      sprint.recurrencyInformation = {
        ...(editingSprint?.recurrencyInformation || {}),
        recurrencyDetails,
      }
    }

    return sprint
  }, [startTime, addedTasksList, editingSprint, title, estimatedTime, selectedRecurrency])

  const onClickCreate = useCallback(() => {
    if (!title) {
      setEmptyTitleErrorShown(true)
      return
    }
    const onClickCreateLogic = async () => {
      const sprint = buildSprintFromState()
      try {
        setLoading(true)
        await onCreate(sprint)
      } catch (err) {
        console.error('Focus block creation failed', err)
        onCancel()
      } finally {
        setLoading(false)
      }
    }
    onClickCreateLogic()
  }, [title, buildSprintFromState, onCreate, onCancel])

  const onClickEdit = useCallback(() => {
    if (!title) {
      setEmptyTitleErrorShown(true)
      return
    }
    const onClickEditLogic = async () => {
      const oldSprint = editingSprint
      const newSprint = buildSprintFromState()

      const edit = async (recurrenceOption) => {
        try {
          setLoading(true)
          await onEdit(newSprint, oldSprint, recurrenceOption)
        } catch (err) {
          onCancel()
        } finally {
          setLoading(false)
        }
      }

      const isOldSprintRecurrent = utils.sprint.isRecurrent(oldSprint)
      const isNewSprintRecurrent = utils.sprint.isRecurrent(newSprint)
      const hasSprintInfoChanged = utils.sprint.hasSprintInfoChanged(oldSprint, newSprint)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldSprint.recurrencyInformation?.recurrencyDetails,
        newSprint.recurrencyInformation?.recurrencyDetails
      )

      if (isNewSprintRecurrent && isOldSprintRecurrent && (hasRecurrenceChanged || hasSprintInfoChanged)) {
        const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date
        let hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        recurringItemPopup(
          {
            forSprint: true,
            hideAllOption,
            hideSingleOption: hasRecurrenceChanged,
            title: translations.sprint.recurrencyPanel.edit.prompt,
          },
          {
            onConfirmed: (recurrenceOption) => edit(recurrenceOption),
          }
        )
      } else {
        edit()
      }
    }
    onClickEditLogic()
  }, [title, buildSprintFromState, onEdit, onCancel, editingSprint, recurringItemPopup])

  const _registerShortcuts = useCallback(() => {
    if (registerShortcuts) {
      registerShortcuts &&
        registerShortcuts(
          [
            {
              key: 'Enter',
              label: isEditingMode ? translations.general.save : translations.general.create,
              callback: () => (isEditingMode ? onClickEdit() : onClickCreate()),
            },
            {
              key: 'Escape',
              label: translations.general.cancel,
              callback: onCancel,
            },
          ],
          componentName
        )
    }
  }, [isEditingMode, onClickCreate, onClickEdit, onCancel, registerShortcuts])

  useEffect(() => {
    if (hasShownChanged) {
      if (isShown) {
        setSelectModeInTaskPanel(true)
        titleInputRef.current && titleInputRef.current.focus()
        if (creatingSprint) {
          setTitle(creatingSprint.title || '')
          setStartTime(moment(creatingSprint.startTime))
          setEstimatedTime(
            creatingSprint.estimatedTime || utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
          )
          if (creatingSprint.tasks) {
            setTasksFromSprint(creatingSprint.tasks)
          }
        } else if (editingSprint) {
          setStartTime(moment(editingSprint.pin.time))
          setEstimatedTime(editingSprint.estimatedTime)
          setTitle(editingSprint.title)
          if (editingSprint.tasks) {
            setTasksFromSprint(editingSprint.tasks)
          }

          if (editingSprint.recurrencyInformation) {
            setSelectedRecurrency(
              recurrenceComponentsUtils.getRecurrencyOptionsFromRecurrencyInformation(editingSprint)
            )
          }
        } else {
          setTitle('')
          setStartTime(moment())
          setEstimatedTime(utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 }))
        }
      } else {
        setSelectModeInTaskPanel(false)
        clearState()
      }
    }
  }, [
    isShown,
    hasShownChanged,
    clearState,
    creatingSprint,
    editingSprint,
    setTasksFromSprint,
    setSelectModeInTaskPanel,
  ])

  useEffect(() => {
    setSelectedRecurrency((prev) => {
      if (
        startTime.date() > 28 &&
        (prev.type === DailyScheduleOptions.MonthlyOnCurrentDay ||
          prev.customSettings?.onOrdinal !== undefined ||
          prev.customSettings?.day)
      ) {
        return produce(prev, (draft) => {
          draft.customSettings = null
          draft.type = DailyScheduleOptions.NoRepeat
        })
      }

      if (prev.customSettings?.onOrdinal) {
        return produce(prev, (draft) => {
          draft.customSettings.onOrdinal = utils.recurrency.details.getOrdinalNumberForStartTime(startTime)
        })
      }

      if (prev.customSettings?.day) {
        return produce(prev, (draft) => {
          draft.customSettings.day = startTime.date()
        })
      }

      return prev
    })
  }, [startTime])

  useEffect(() => {
    if (isShown) {
      _registerShortcuts()
      return () => unregisterShortcuts?.(componentName)
    }
  }, [isShown, _registerShortcuts, unregisterShortcuts])

  const _onCurrentSprintChange = useCallback(
    ({ param, value }) => {
      const sprint = buildSprintFromState()

      if (param === 'range') {
        set(sprint, 'pin', value.pin)
        set(sprint, 'estimatedTime', value.estimatedTime)
      } else {
        set(sprint, param, value)
      }

      onCurrentSprintChange(sprint)
    },
    [buildSprintFromState, onCurrentSprintChange]
  )

  const onChangeTitle = useCallback(
    (e) => {
      setTitle(e.target.value)
      if (isEmptyTitleErrorShown) {
        setEmptyTitleErrorShown(false)
      }
      _onCurrentSprintChange({ param: 'title', value: e.target.value })
    },
    [isEmptyTitleErrorShown, _onCurrentSprintChange]
  )

  const onRemoveTask = useMemo(
    () =>
      throttle((task) => {
        removeTaskFromSprint(task.id)
        if (
          editingSprint &&
          editingSprint.tasks &&
          editingSprint.tasks.find((sprintTask) => sprintTask.id === task.id)
        ) {
          addTmpItemToTaskPanel(task.id)
        } else {
          removeFilteredId(task.id)
        }
      }, 300),
    [removeTaskFromSprint, editingSprint, addTmpItemToTaskPanel, removeFilteredId]
  )

  const _onCreateTask = useCallback(
    async (task) => {
      if (createInlineTask) {
        task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
        addTaskToSprint(task)
        addFilteredId(task.id)

        const response = await createInlineTask({
          task,
          dontShowCreationAlert: true,
          context: { componentSource: 'sprintComposer' },
        })
        if (!response) {
          console.error('task creation failed')
          return
        }
        const createdTask = response.task

        if (createdTask.id !== task.id) {
          updateTaskFromSprint(createdTask, task.id)
          replaceFilteredId(task.id, createdTask.id)
        }
      }
    },
    [createInlineTask, addTaskToSprint, addFilteredId, updateTaskFromSprint, replaceFilteredId, session]
  )

  const _onDelete = useCallback(() => {
    onDelete && onDelete(editingSprint)
  }, [editingSprint, onDelete])

  const onChangeDate = useCallback(
    (value) => {
      setStartTime((startTime) => {
        const newStartTime = moment(value).hours(startTime.hours()).minutes(startTime.minutes())
        _onCurrentSprintChange({ param: 'pin.time', value: newStartTime })
        return newStartTime
      })
    },
    [_onCurrentSprintChange]
  )

  const handleDateChange = useCallback(
    ({ value }) => {
      onChangeDate(value)
    },
    [onChangeDate]
  )

  const handleTimeRangeChange = useCallback(
    (range) => {
      const rangeData = utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(range)
      _onCurrentSprintChange?.({ param: 'range', value: rangeData })
    },
    [_onCurrentSprintChange]
  )

  const onSaveTask = useCallback(
    (task) => {
      const oldTask = findItemById(task.id)
      const taskIsRecurrent = utils.task.isRecurrent(task)

      const toSaveTask = produce(task, (draft) => {
        delete draft.sprintInfo
        draft.estimatedTime = oldTask.estimatedTime
        draft.pin = oldTask.pin
        draft.when = oldTask.when

        if (oldTask?.sprintInfo) {
          draft.sprintInfo = oldTask.sprintInfo
        }
      })

      const haveTaskAttributesChanged = utils.task.areTaskSimpleAttributesNotEqual(toSaveTask, oldTask)

      if (!haveTaskAttributesChanged) return

      if (taskIsRecurrent) {
        recurringItemPopup(
          {
            hideAllOption: true,
            hideSingleOption: true,
            hideTitle: true,
            noOptionsMessageText: translations.task.recurrencyPanel.noOptionsSingleTask,
            noOptionsValue: InstanceOptions.Single,
          },
          {
            onConfirmed: (recurrenceOption) => handleTaskSave(toSaveTask, oldTask, recurrenceOption),
            onCancelled: () => {
              updateTaskFromSprint(oldTask, oldTask.id)
            },
          }
        )
        updateTaskFromSprint(task, task.id)
      } else {
        handleTaskSave(toSaveTask, oldTask)
        updateTaskFromSprint(task, task.id)
      }
    },
    [findItemById, handleTaskSave, recurringItemPopup, updateTaskFromSprint]
  )

  const onDeleteTask = useCallback(
    (taskId) => {
      onClickDelete(taskId, () => {
        removeTaskFromSprint(taskId)
      })
    },
    [onClickDelete, removeTaskFromSprint]
  )

  const onCompleteTask = useCallback(
    ({ id }) => {
      removeTaskFromSprint(id)

      completeTask({ id })
    },
    [completeTask, removeTaskFromSprint]
  )

  const handleRecurrencyChosen = useCallback((recurrencyData) => {
    setSelectedRecurrency(recurrencyData)
  }, [])

  const createLineProps = {
    onCreate: _onCreateTask,
    innerLeftPadding: 47,
    innerRightPadding: 47,
    hideScheduleSection: true,
    hideSprint: true,
    creating: true,
  }

  const duration = getItemDurationInMinutes({
    estimatedTime,
    pin: {
      time: startTime,
    },
  })
  const firstDayOfWeek = session?.user?.settingsPreferences?.calendar?.firstDay

  return (
    <ComposerContainer isShown={isShown} enterDelay={enterDelay} withCalendar={props.showCalendar}>
      <Body>
        <InputsContainer className='fs-mask'>
          <TitleSection
            onChange={onChangeTitle}
            title={title}
            ref={titleInputRef}
            isEmptyTitleErrorShown={isEmptyTitleErrorShown}
          />
          <Separator />
          <TimeInputsContainer>
            <TimeInputContainer>
              <DatePickerInput
                closeOnSelection
                firstDayOfWeek={firstDayOfWeek}
                onChange={handleDateChange}
                startTime={startTime}
              />
            </TimeInputContainer>
            <TimeInputContainer>
              <TimeRange duration={duration} onChange={handleTimeRangeChange} startTime={startTime} />
            </TimeInputContainer>
            <TimeInputContainer>
              <Recurrency
                customTypeSettings={selectedRecurrency.customSettings}
                everyOption={selectedRecurrency.every}
                firstDayOfWeek={firstDayOfWeek}
                onChange={handleRecurrencyChosen}
                recurrencyType={selectedRecurrency.type}
                sprintScheduleType={selectedRecurrency.schedule}
                sprintStartTime={startTime}
              />
            </TimeInputContainer>
          </TimeInputsContainer>
        </InputsContainer>

        <TasksTitle>{translations.sprint.tasksSectionTitle}</TasksTitle>
        <Droppable droppableId={JSON.stringify({ id: droppableId, type: droppableType })}>
          {(droppableProvided) => (
            <TaskList ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              <DraggableTaskList
                onDeleteTask={onDeleteTask}
                onRemoveTask={onRemoveTask}
                onSaveTask={onSaveTask}
                onCompleteTask={onCompleteTask}
                pointerLeftPositionRef={pointerLeftPosition}
                tasks={addedTasksList}
              />
              {droppableProvided.placeholder}
            </TaskList>
          )}
        </Droppable>
        <HomebaseLine {...createLineProps} />
        {!addedTasksList ||
          (addedTasksList.length === 0 && (
            <EmptyTaskListPlaceHolder>{translations.sprint.addCreateTaskToBlock}</EmptyTaskListPlaceHolder>
          ))}
      </Body>
      <Footer
        customCancelButton={customCancelButton}
        isLoading={isLoading}
        createButtonLabel={isEditingMode ? translations.general.save : translations.sprint.create}
        onCreate={isEditingMode ? onClickEdit : onClickCreate}
        onCancel={onCancel}
        onDelete={isEditingMode ? _onDelete : undefined}
        isShown={isShown}
      />
    </ComposerContainer>
  )
}

const DraggableTaskList = React.memo(function DraggableTaskList({
  onDeleteTask,
  onRemoveTask,
  onSaveTask,
  onCompleteTask,
  pointerLeftPositionRef,
  tasks,
}) {
  return tasks.map((task, index) => {
    return (
      <Draggable draggableId={`${task.id}`} index={index} key={task.id} type={sprintDraggableType}>
        {(draggableProvided, snapshot) => {
          let draggableStyle = draggableProvided.draggableProps.style
          const isDragging = snapshot.isDragging

          if (isDragging) {
            const draggedWidth = taskPanelContainerWidth + 32
            draggableStyle = {
              ...draggableStyle,
              left: pointerLeftPositionRef.current - draggedWidth / 2,
              width: `${draggedWidth}px`,
            }
          }

          return (
            <HomebaseLine
              alignActionsPopupToContainer
              disableLinks
              draggableProps={draggableProvided.draggableProps}
              draggableStyle={draggableStyle}
              dragHandleProps={draggableProvided.dragHandleProps}
              hideDateInput
              hideScheduleSection
              hideSprint
              hideStartButton
              hideWhenDate
              innerLeftPadding={47}
              innerRef={draggableProvided.innerRef}
              innerRightPadding={47}
              isCreating
              isDraggable
              isDragging={isDragging}
              item={task}
              key={task.id}
              lineThrough={!!task.completed}
              marginAfterLine={'8px'}
              onClickLeftArrow={onRemoveTask}
              onDelete={onDeleteTask}
              onSave={onSaveTask}
              onComplete={onCompleteTask}
              animateComplete={true}
            />
          )
        }}
      </Draggable>
    )
  })
})

const InputsContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const EmptyTaskListPlaceHolder = styled.span`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.darkGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`

const TasksTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${styles.colors.textMediumDarkColor};
  width: 100%;
  text-align: center;
  margin: 42px 0;
`

const TimeInputsContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  position: relative;

  & > :nth-child(3) {
    margin-left: auto;
  }
`

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
`

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export default React.memo(container(SprintComposer))
