import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'
import clone from 'lodash/clone'
import differenceBy from 'lodash/differenceBy'
import moment from 'moment'

import { sprint as sprintApi } from 'gipsy-api'
import { models, translations, utils } from 'gipsy-misc'
import { InstanceOption, Sprint, Task } from 'gipsy-misc/types'

import { getAllInstancesOfRecSprint } from 'features/hooks/utils/sprints'
import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import { setHighlightedEventId, updateCalendarDate } from 'store/calendar/actions'
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
  replaceItem,
  replaceItems,
  updateItem,
  updateItems,
} from 'store/items/actions'
import { getFindItemByIdFn, getSprintsById } from 'store/items/selectors'

const { InstanceOptions } = models.recurrency

export default function useSprintHooks({ recurringItemPopup }) {
  const dispatch = useDispatch()
  const calendarHighlightedEventId = useSelector((state) => state.calendar.highlightedEventId)
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)
  const sprintsById = useSelector((state) => getSprintsById(state.items))

  const addRecurrenceToSingleSprint = useCallback(
    (sprint: Sprint) => {
      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        sprint,
        sprint.recurrencyInformation.recurrencyDetails
      )
      const sprints = utils.recurrency.sprints.scheduleNextSprintsForDay(sprint.when.date, recurringSprint)

      if (!sprints) return null

      const [firstInstance] = sprints

      if (firstInstance && sprint.tasks) {
        let firstInstanceTasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, firstInstance.id)
        firstInstanceTasks = firstInstanceTasks.map((task) => {
          task.pin = undefined
          return task
        })

        firstInstance.tasks = firstInstanceTasks
      }

      dispatch(addItems(sprints))
      return { recurringSprint, sprints }
    },
    [dispatch, session.id]
  )

  const createRecurrentSprint = useCallback(
    async (toCreateSprint: Sprint) => {
      const result = addRecurrenceToSingleSprint(toCreateSprint)

      if (!result) return

      const { recurringSprint, sprints } = result
      const [firstInstance] = sprints

      try {
        toCreateSprint.creationTime = recurringSprint.creationTime
        const out = await sprintApi.create(toCreateSprint)

        if (!out.instances) return out

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== sprints.length || firstResponseInstance.id !== firstInstance.id) {
          dispatch(replaceItems(sprints, out.instances))
        }

        return out
      } catch (err: any) {
        dispatch(handleAPIError(err, { sprint: toCreateSprint }))
        return { error: err?.data?.code }
      }
    },
    [addRecurrenceToSingleSprint, dispatch]
  )

  const createSingleInstanceSprint = useCallback(
    async (toCreateSprint: Sprint) => {
      const sprint: Sprint = utils.ids.addIdToItem(toCreateSprint, models.item.type.SPRINT, session.id)
      sprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks || [], sprint.id)

      if (sprint.tasks) {
        sprint.tasks = sprint.tasks.map((task) => {
          task.pin = undefined
          return task
        })
      }

      dispatch(addItem(sprint))

      try {
        const out = await sprintApi.create(sprint)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
        let result = sprint

        if (out && out.sprint && out.sprint.id !== sprint.id) {
          const newSprint = out.sprint
          newSprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, newSprint.id)
          dispatch(replaceItem(sprint, newSprint))
          result = newSprint
        }

        return result
      } catch (err: any) {
        dispatch(handleAPIError(err, { sprint }))
        return { error: err?.data?.code }
      }
    },
    [dispatch, session.id]
  )

  const createSprint = useCallback(
    async (toCreateSprint: Sprint) => {
      let result

      if (toCreateSprint.recurrencyInformation?.recurrencyDetails) {
        result = await createRecurrentSprint(toCreateSprint)
      } else {
        result = await createSingleInstanceSprint(toCreateSprint)
      }

      return result
    },
    [createRecurrentSprint, createSingleInstanceSprint]
  )

  const deleteSprint = useCallback(
    async (sprintId: string, recurrenceOption: InstanceOption) => {
      let sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }

      if (!recurrenceOption || recurrenceOption === InstanceOptions.Single) {
        dispatch(removeItem(sprint))
      } else {
        const [recurrenceId, sprintDateStr] = sprintId.split('_')
        const sprintsToRemove: Sprint[] = []
        const sprintsToUpdate: Sprint[] = []

        if (recurrenceOption === InstanceOptions.Next) {
          const sprintDate = moment(sprintDateStr)

          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const [, currentSprintDateStr] = currentSprintId.split('_')
            const currentSprintDate = moment(currentSprintDateStr)

            let currentSprint = findItemById(currentSprintId)
            if (currentSprint && currentSprint.recurrencyInformation) {
              if (currentSprintDate.isSameOrAfter(sprintDate)) {
                if (currentSprint) {
                  sprintsToRemove.push(currentSprint)
                }
              } else {
                currentSprint = produce(currentSprint, (draft) => {
                  draft.recurrencyInformation = undefined
                })
                sprintsToUpdate.push(currentSprint)
              }
            }
          })
        } else {
          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const currentSprint = findItemById(currentSprintId)

            if (currentSprint && currentSprint.recurrencyInformation) {
              // we need to make sure the sprint is recurring. The id isn't enough
              sprintsToRemove.push(currentSprint)
            }
          })
        }

        dispatch(replaceItems(sprintsToRemove, sprintsToUpdate))
      }

      try {
        await sprintApi.del(sprintId, recurrenceOption)
        RealTime.publishMessage(sprintId, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId }))
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const updateLocalSprintFromEdit = useCallback(
    (newSprint: Sprint, oldSprint: Sprint) => {
      const newTasksInSprint = differenceBy(newSprint.tasks || [], oldSprint.tasks || [], 'id')

      if (newTasksInSprint.length) {
        // need to do this because tasks in newTasksInSprint don't have a when date
        const tasksInStateToBeRemoved = newTasksInSprint.reduce((taskList, taskToRemove) => {
          const task = findItemById(taskToRemove.id)

          if (task) {
            taskList.push(task)
          }

          return taskList
        }, [])

        dispatch(removeItems(tasksInStateToBeRemoved))
      }

      const removedTasksFromSprint = differenceBy(oldSprint.tasks || [], newSprint.tasks || [], 'id')

      if (removedTasksFromSprint.length) {
        const tasksToBeAdded = removedTasksFromSprint.map((taskToRemove) =>
          produce(taskToRemove, (draft) => {
            draft.when.date = moment(newSprint.pin.time).format('YYYY-MM-DD')
            delete draft.sprintInfo
          })
        )

        dispatch(addItems(tasksToBeAdded))
      }

      dispatch(updateItem(newSprint))
    },
    [dispatch, findItemById]
  )

  const updateSprintWithTasksAndRecurrencyInfoOfSeries = useCallback(
    (instances, newSprint: Sprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, instance, after } = splittedInstances

      const updatedBeforeInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })
      })

      dispatch(replaceItems(before, updatedBeforeInstances))

      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        newSprint,
        newSprint.recurrencyInformation.recurrencyDetails
      )

      const sprints = utils.recurrency.sprints.scheduleNextSprintsForDay(newSprint.when.date, recurringSprint)

      if (!sprints) return { createdRecurringSprint: recurringSprint }

      const { filledInstances: updatedInstances, toUpdateTasks } = utils.recurrency.sprints.fillNewInstancesWithTasks(
        sprints,
        after,
        newSprint.tasks!
      )
      const oldRecItems = [instance, ...after]
      dispatch(replaceItems(oldRecItems, updatedInstances))
      dispatch(updateItems(toUpdateTasks))

      return { createdRecurringSprint: recurringSprint, createdInstances: updatedInstances }
    },
    [dispatch, session.id]
  )

  const updateSprintWithTasksWhenRecurrencyDetailsAreSame = useCallback(
    (instances, newSprint: Sprint, oldSprint: Sprint, recurrenceOption: InstanceOption) => {
      const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date

      switch (true) {
        case recurrenceOption === InstanceOptions.Single: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          return
        }
        case hasWhenDateChanged: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }

        case recurrenceOption === InstanceOptions.All || recurrenceOption === InstanceOptions.Next: {
          if (utils.sprint.areSprintAttributesNotEqual(newSprint, oldSprint)) {
            let instancesToUpdate = instances

            if (recurrenceOption === InstanceOptions.Next) {
              const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)
              instancesToUpdate = splittedInstances.after
            }

            const newPinMoment = moment(newSprint.pin.time)

            const updatedInstances = produce(instancesToUpdate, (draft) => {
              draft.forEach((instance) => {
                if (instance.id === newSprint.id) return

                instance.title = newSprint.title
                instance.estimatedTime = newSprint.estimatedTime
                instance.pin.time = moment(instance.pin.time).set({
                  hour: newPinMoment.hour(),
                  minute: newPinMoment.minute(),
                  second: newPinMoment.second(),
                })
              })
            })

            dispatch(updateItems(updatedInstances))
            updateLocalSprintFromEdit(newSprint, oldSprint)
          } else {
            updateLocalSprintFromEdit(newSprint, oldSprint)
          }
          break
        }

        // single option
        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
        }
      }
    },
    [dispatch, updateLocalSprintFromEdit, updateSprintWithTasksAndRecurrencyInfoOfSeries]
  )

  const updateSprintAndRemoveRecurrencyOfSeries = useCallback(
    (instances, newSprint: Sprint, oldSprint: Sprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, after } = splittedInstances

      const updatedInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })

        draft.push(newSprint)
      })

      dispatch(updateItems(updatedInstances))
      dispatch(removeItems(after))
      updateLocalSprintFromEdit(newSprint, oldSprint)
    },
    [dispatch, updateLocalSprintFromEdit]
  )

  const updateRecurringSprintInstanceWithTasks = useCallback(
    (newSprint: Sprint, oldSprint: Sprint, recurrenceOption: InstanceOption) => {
      const instances = getAllInstancesOfRecSprint(
        sprintsById,
        findItemById,
        utils.sprint.getRecSprintId(oldSprint),
        true
      )

      switch (true) {
        case !newSprint.recurrencyInformation: {
          return updateSprintAndRemoveRecurrencyOfSeries(instances, newSprint, oldSprint)
        }

        case utils.recurrency.options.isRecurrenceEqual(
          oldSprint.recurrencyInformation?.recurrencyDetails,
          newSprint.recurrencyInformation?.recurrencyDetails
        ): {
          return updateSprintWithTasksWhenRecurrencyDetailsAreSame(instances, newSprint, oldSprint, recurrenceOption)
        }

        default: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }
      }
    },
    [
      findItemById,
      sprintsById,
      updateSprintAndRemoveRecurrencyOfSeries,
      updateSprintWithTasksAndRecurrencyInfoOfSeries,
      updateSprintWithTasksWhenRecurrencyDetailsAreSame,
    ]
  )

  const handleSprintSave = useCallback(
    async (
      newSprint: Sprint,
      oldSprint: Sprint,
      recurrenceOption?: InstanceOption,
      { onRecurrenceComputed }: { onRecurrenceComputed?: (...args: any) => any } = {}
    ) => {
      newSprint.tasks?.forEach?.((task, index, tasks) => {
        if (task?.pin?.time) {
          task.pin = undefined
          tasks[index] = task
        }
      })

      let createdInstances
      switch (true) {
        case !!oldSprint.recurrencyInformation: {
          const result = updateRecurringSprintInstanceWithTasks(
            newSprint,
            oldSprint,
            recurrenceOption as InstanceOption
          )
          if (!!result) {
            // to make sure the new id generated is the same as the one the front end generated
            newSprint = produce(newSprint, (draft) => {
              draft.recurrencyInformation.recurrencyDetails = result?.createdRecurringSprint.recurrencyDetails
              draft.creationTime = result?.createdRecurringSprint.creationTime
            })
            createdInstances = result?.createdInstances
          }

          onRecurrenceComputed?.(result)
          break
        }

        case !oldSprint.recurrencyInformation && !!newSprint.recurrencyInformation: {
          dispatch(removeItem(oldSprint))
          const result = addRecurrenceToSingleSprint(newSprint)

          if (result) {
            const { recurringSprint: createdRecurringSprint, sprints } = result
            // to make sure the new id generated is the same as the one the front end generated
            newSprint.creationTime = createdRecurringSprint.creationTime
            createdInstances = sprints
          }

          break
        }

        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          break
        }
      }

      try {
        const sprintWithTaskIds = produce(newSprint, (draft) => {
          if (draft.tasks) {
            draft.tasksId = draft.tasks.map((t) => t.id)
          }
        })

        const out = await sprintApi.editWithTasks(sprintWithTaskIds, { recurrenceOption })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (!out?.instances) return

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== createdInstances?.length || firstResponseInstance.id !== createdInstances[0].id) {
          dispatch(replaceItems(createdInstances, out.instances))
        }
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [addRecurrenceToSingleSprint, dispatch, updateLocalSprintFromEdit, updateRecurringSprintInstanceWithTasks]
  )

  const saveSprint = useCallback(
    (
      updatedSprint: Sprint,
      {
        onCancel,
        onRecurrenceComputed,
      }: { onCancel?: (...args: any) => any; onRecurrenceComputed?: (...args: any) => any } = {}
    ) => {
      const newSprint = clone(updatedSprint)
      const oldSprint = findItemById(updatedSprint.id)

      if (!oldSprint) {
        console.warn('-- sprint not found')
        return
      }

      const isOldSprintRecurrent = utils.sprint.isRecurrent(oldSprint)
      const isNewSprintRecurrent = utils.sprint.isRecurrent(newSprint)
      const sprintAttributesChanged = utils.sprint.areSprintAttributesNotEqual(newSprint, oldSprint)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldSprint.recurrencyInformation?.recurrencyDetails,
        newSprint.recurrencyInformation?.recurrencyDetails
      )

      if (!isOldSprintRecurrent && !isNewSprintRecurrent && !sprintAttributesChanged) return

      if (isOldSprintRecurrent && isNewSprintRecurrent) {
        if (!hasRecurrenceChanged && !sprintAttributesChanged) return

        const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date
        const hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        const hideTitle = hideAllOption && hasRecurrenceChanged
        recurringItemPopup(
          {
            forSprint: true,
            hideAllOption,
            hideSingleOption: hasRecurrenceChanged,
            hideTitle,
            title: translations.sprint.recurrencyPanel.edit.prompt,
          },
          {
            onConfirmed: (recurrenceOption) =>
              handleSprintSave(newSprint, oldSprint, recurrenceOption, { onRecurrenceComputed }),
            onCancelled: onCancel,
          }
        )
      } else {
        handleSprintSave(newSprint, oldSprint, undefined, undefined)
      }
    },
    [findItemById, handleSprintSave, recurringItemPopup]
  )

  const rescheduleTasksToNextActiveInstance = useCallback(
    (recSprintId: string, pastSprintId: string, tasks: Task[]) => {
      let activeInstances = getAllInstancesOfRecSprint(sprintsById, findItemById, recSprintId, true)
      activeInstances = utils.list.sortListByScheduledTime(activeInstances)
      if (activeInstances.length > 0) {
        for (let instance of activeInstances) {
          if (instance.id !== pastSprintId) {
            const nextScheduledInstance = produce(instance, (draft) => {
              draft.tasks = tasks
            })
            dispatch(updateItem(nextScheduledInstance))
            break
          }
        }
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const endSprint = useCallback(
    async (sprintId: string) => {
      const sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }
      const tasks = sprint.tasks || []

      const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
      const updatedEndedSprint = produce(sprint, (draft) => {
        draft.completionTime = completionTime
        draft.estimatedTime = estimatedTime
        draft.tasks = undefined
      })

      dispatch(updateItem(updatedEndedSprint))

      if (tasks?.length > 0) {
        if (utils.sprint.isRecurrent(sprint)) {
          rescheduleTasksToNextActiveInstance(utils.sprint.getRecSprintId(sprint), sprint.id, tasks)
        } else {
          const tasksToBeAdded = tasks.map((taskToUpdate) =>
            produce(taskToUpdate, (draft) => {
              draft.when.date = moment(sprint.pin.time).format('YYYY-MM-DD')
              delete draft.sprintInfo
            })
          )
          dispatch(addItems(tasksToBeAdded))
        }
      }

      try {
        await sprintApi.end(sprint.id)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId: sprint?.id }))
      }
    },
    [dispatch, findItemById, rescheduleTasksToNextActiveInstance]
  )

  const onClickSprint = useCallback(
    (sprint: Sprint) => {
      dispatch(setHighlightedEventId(sprint.id))
      dispatch(updateCalendarDate(sprint.pin.time))
    },
    [dispatch]
  )

  const onClickOutsideSprint = useCallback(
    (sprint: Sprint) => {
      if (calendarHighlightedEventId === sprint.id) {
        dispatch(setHighlightedEventId(''))
      }
    },
    [calendarHighlightedEventId, dispatch]
  )

  const onTaskDroppedInSprint = useCallback(
    async ({
      destinationIndex = 0,
      sprintId,
      taskId,
    }: {
      destinationIndex: number
      sprintId: string
      taskId: string
    }) => {
      const targetSprint = clone(findItemById(sprintId))
      const task = clone(findItemById(taskId))

      if (!targetSprint || !task) {
        console.warn(`-- ${targetSprint ? 'task' : 'sprint'} not found`)
        return
      }

      const rank = destinationIndex + 1
      const newSprintInfo = {
        id: targetSprint.id,
        title: targetSprint.title,
        estimatedTime: targetSprint.estimatedTime,
        pin: targetSprint.pin,
        rank,
      }

      const updatedTask = utils.task.computeTaskOnChange(task, {
        method: undefined,
        paramName: 'sprintInfo',
        value: newSprintInfo,
      })

      const updatedItems = dispatch(updateItem(updatedTask))
      const sprint = updatedItems.sprints[updatedTask?.sprintInfo?.id]

      try {
        await sprintApi.dragAndDropTasks({
          taskId: task.id,
          sprintId: sprint.id,
          toRank: rank,
        })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
      }
    },
    [dispatch, findItemById]
  )

  return {
    createSprint,
    deleteSprint,
    endSprint,
    handleSprintSave,
    onClickOutsideSprint,
    onClickSprint,
    onTaskDroppedInSprint,
    saveSprint,
  }
}
