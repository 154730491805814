import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { FocusSession, Sprint, Task } from 'gipsy-misc/types'

import { addCalendarEvent, removeCalendarEvent, updateCalendarEvent } from 'store/calendar/actions'

type CalendarItem = FocusSession | Sprint | Task

export default function useCalendarHooks() {
  const dispatch = useDispatch()

  const addItemToCalendar = useCallback(
    (item: CalendarItem, color: string, isSprint: boolean) => {
      dispatch(addCalendarEvent({ color, isSprint, item }))
    },
    [dispatch]
  )

  const removeItemFromCalendar = useCallback(
    (id: string) => {
      dispatch(removeCalendarEvent({ itemId: id }))
    },
    [dispatch]
  )

  const updateCalendarItem = useCallback(
    (idToUpdate: string, item: CalendarItem) => {
      dispatch(
        updateCalendarEvent({
          idToUpdate,
          item,
        })
      )
    },
    [dispatch]
  )

  return {
    addItemToCalendar,
    removeItemFromCalendar,
    updateCalendarItem,
  }
}
