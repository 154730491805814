import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAllItems, getFindItemByIdFn, getSprints, getSprintTasks } from 'store/items/selectors'
import {
  popShortcutsGroup as _popShortcutsGroup,
  pushShortcutsGroup as _pushShortcutsGroup,
} from 'store/shortcuts/actions'

import useBatchHooks from './useBatchHooks'
import useCalendarHooks from './useCalendarHooks'
import useFocusSessionHooks from './useFocusSessionHooks'
import usePopupsHooks from './usePopupsHooks'
import useSprintHooks from './useSprintHooks'
import useTaskHooks from './useTaskHooks'

export default function usePageActions() {
  const { addItemToCalendar, removeItemFromCalendar, updateCalendarItem } = useCalendarHooks()
  const dispatch = useDispatch()
  const {
    addedLinksFSDeletePopup,
    closePopup,
    focusSessionDeletePopup,
    openLoadingPopup,
    recurringItemPopup,
    sprintDeletePopup,
    taskDeletePopUp,
  } = usePopupsHooks()
  const {
    deleteFocusSession,
    handleCompletedSession,
    onClickDeleteFocusSession,
    onClickFocusSession,
    updateFocusSession,
  } = useFocusSessionHooks({
    addedLinksFSDeletePopup,
    focusSessionDeletePopup,
    removeItemFromCalendar,
    updateCalendarItem,
  })
  const allItems = useSelector((state) => getAllItems(state.items))
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const sprints = useSelector((state) => getSprints(state.items))
  const sprintTasks = useSelector((state) => getSprintTasks(state.items))
  const {
    createSprint,
    deleteSprint,
    endSprint,
    handleSprintSave,
    onClickOutsideSprint,
    onClickSprint,
    onTaskDroppedInSprint,
    saveSprint,
  } = useSprintHooks({ recurringItemPopup })
  const {
    archiveTask,
    completeTask,
    completeTaskFromFS,
    createInlineTask,
    deleteTask,
    getFocusedTaskId,
    handleTaskSave,
    isTaskCreationAlertShown,
    onClickDelete,
    onTitleChange,
    saveTask,
    showTaskCreationAlert,
    startFsAndCreateTask,
    uncompleteTask,
  } = useTaskHooks({
    addItemToCalendar,
    recurringItemPopup,
    removeItemFromCalendar,
    taskDeletePopUp,
  })
  const { addTasksToSprint, completeTasks, deleteItems, editTasks, rescheduleItems } = useBatchHooks({
    addItemToCalendar,
  })

  const popShortcutsGroup = useCallback(
    (...args) => {
      dispatch(_popShortcutsGroup(...args))
    },
    [dispatch]
  )

  const pushShortcutsGroup = useCallback(
    (...args) => {
      dispatch(_pushShortcutsGroup(...args))
    },
    [dispatch]
  )

  return {
    addItemToCalendar,
    addTasksToSprint,
    allItems,
    archiveTask,
    closePopup,
    completeTask,
    completeTaskFromFS,
    completeTasks,
    createInlineTask,
    createSprint,
    deleteFocusSession,
    deleteItems,
    deleteSprint,
    deleteTask,
    editTasks,
    endSprint,
    findItemById,
    getFocusedTaskId,
    handleCompletedSession,
    handleSprintSave,
    handleTaskSave,
    isTaskCreationAlertShown,
    onClickDelete,
    onClickDeleteFocusSession,
    onClickFocusSession,
    onClickOutsideSprint,
    onClickSprint,
    onTaskDroppedInSprint,
    onTitleChange,
    openLoadingPopup,
    popShortcutsGroup,
    pushShortcutsGroup,
    recurringItemPopup,
    removeItemFromCalendar,
    rescheduleItems,
    saveSprint,
    saveTask,
    showTaskCreationAlert,
    sprintDeletePopup,
    sprints,
    sprintTasks,
    startFsAndCreateTask,
    taskDeletePopUp,
    uncompleteTask,
    updateCalendarItem,
    updateFocusSession,
  }
}
