import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clone from 'lodash/clone'
import moment from 'moment'
import { produce } from 'immer'

import { task as taskApi } from 'gipsy-api'
import { constants, models, styles, translations, utils } from 'gipsy-misc'
import { FocusSession, InstanceOption, Task } from 'gipsy-misc/types'

import { getAllInstancesOfRecTask } from 'features/hooks/utils/tasks'
import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
  replaceItem,
  replaceItems,
  updateItem,
  updateItems,
} from 'store/items/actions'
import { getFindItemByIdFn, getTasksById } from 'store/items/selectors'
import { createTaskAndFs, endSession, updateFocusSessionTitle } from 'store/session/actions'
import { patchTaskRequest, toggleCompleteTaskRequest } from 'store/task/actions'

const { InstanceOptions } = models.recurrency

export default function useTaskHooks({
  addItemToCalendar,
  recurringItemPopup,
  removeItemFromCalendar,
  taskDeletePopUp,
}) {
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)
  const tasksById = useSelector((state) => getTasksById(state.items))

  const [isTaskCreationAlertShown, setIsTaskCreationAlertShown] = useState(false)

  const patchers = useRef({})
  const taskCreationAlertTimeout = useRef(0)

  const sendPatchRequest = useCallback(
    ({ extraParams, method, paramName, task, value }) => {
      if (!patchers.current[task.id]) {
        patchers.current[task.id] = new utils.TaskPatcher(constants.delayBeforePatch.default)
      }

      try {
        const { params, body } = utils.task.computePatchRequest(task, { paramName, value, method }, extraParams)
        return new Promise((resolve, reject) => {
          patchers.current[task.id].put(paramName, method, () => {
            return dispatch(patchTaskRequest(task.id, params, body))
              .then((args) => {
                resolve(args)
              })
              .catch((args) => {
                reject(args)
              })
          })
        })
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch]
  )

  const archiveTask = useCallback(
    async ({ id }: { id: string }) => {
      const task = findItemById(id)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const paramName = 'archived'
      const value = true

      dispatch(removeItem(task))

      await sendPatchRequest({ method: 'change', paramName, task, value })
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const completeTask = useCallback(
    async ({ id }: { id: string }, extraParams, toAddFocusSession: FocusSession) => {
      let oldTask = clone(findItemById(id))
      let completionFocusSession = toAddFocusSession
      const now = moment()

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      if (oldTask.type !== models.item.type.TASK) return

      if (!completionFocusSession) {
        const completionTime = utils.task.getCompletionTime(oldTask)
        completionFocusSession = utils.focussession.createDefaultFocusSession({
          task: oldTask,
          endTime: completionTime,
        })
      }

      const completedTask = utils.task.updateTaskWhenComplete(oldTask)

      if (completionFocusSession) {
        completedTask.focusSessions = (completedTask.focusSessions || []).concat([completionFocusSession])
        addItemToCalendar(
          { ...completionFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )
      }

      if (oldTask && oldTask.sprintInfo?.id) {
        let sprint = clone(findItemById(oldTask.sprintInfo?.id))
        if (sprint) {
          sprint.tasks = sprint.tasks?.filter((completedTask) => completedTask.id !== id)
          if (utils.sprint.shouldCompleteSprint(sprint, now)) {
            const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
            sprint.completionTime = completionTime
            sprint.estimatedTime = estimatedTime
          }
          dispatch(updateItem(sprint))
        }

        dispatch(addItem(completedTask))
      } else {
        dispatch(updateItem(completedTask))
      }

      const out = await dispatch(toggleCompleteTaskRequest(id, 1, undefined, extraParams))
      const updatedTask = clone(completedTask)

      if (out?.createdFocusSession) {
        if (completionFocusSession) {
          removeItemFromCalendar(completionFocusSession.id)
          updatedTask.focusSessions = (updatedTask.focusSessions || []).filter(
            (fs) => fs.id !== completionFocusSession.id
          )
        }

        addItemToCalendar(
          { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )

        updatedTask.focusSessions = (updatedTask.focusSessions || []).concat([out.createdFocusSession])
        dispatch(updateItem(updatedTask))
      }
    },
    [addItemToCalendar, dispatch, findItemById, removeItemFromCalendar]
  )

  const completeTaskFromFS = useCallback(
    async ({ id }: { id: string }, completionFocusSession: FocusSession) => {
      completeTask({ id }, { inFocusSession: true }, completionFocusSession)
      dispatch(endSession(completionFocusSession))
    },
    [completeTask, dispatch]
  )

  const uncompleteTask = useCallback(
    async (id: string) => {
      let task = clone(findItemById(id))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = produce(task, (draft) => {
        draft.completionTime = ''
        draft.completed = 0
      })

      dispatch(updateItem(updatedTask))

      await dispatch(toggleCompleteTaskRequest(id, 0))
    },
    [dispatch, findItemById]
  )

  const deleteTask = useCallback(
    async (taskId: string, recurrenceOption: InstanceOption) => {
      const task = findItemById(taskId)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      if (!recurrenceOption || recurrenceOption === InstanceOptions.Single) {
        dispatch(removeItem(task))
      } else {
        const [recurrenceId, taskDateStr] = taskId.split('_')
        const tasksToRemove: Task[] = []
        const tasksToUpdate: Task[] = []

        if (recurrenceOption === InstanceOptions.Next) {
          const taskDate = moment(taskDateStr)

          tasksById.forEach((currentTaskId) => {
            if (!currentTaskId.includes(recurrenceId)) return

            const [, currentSprintDateStr] = currentTaskId.split('_')
            const currentSprintDate = moment(currentSprintDateStr)

            let currentTask = findItemById(currentTaskId)
            if (currentTask && currentTask.recurrencyInformation) {
              if (currentSprintDate.isSameOrAfter(taskDate)) {
                if (currentTask) {
                  tasksToRemove.push(currentTask)
                }
              } else {
                currentTask = produce(currentTask, (draft) => {
                  draft.recurrencyInformation = undefined
                })
                tasksToUpdate.push(currentTask)
              }
            }
          })
        } else {
          tasksById.forEach((currentTaskId) => {
            if (!currentTaskId.includes(recurrenceId)) return

            const currentTask = findItemById(currentTaskId)

            if (currentTask && currentTask.recurrencyInformation) {
              // we need to make sure the sprint is recurring. The id isn't enough
              tasksToRemove.push(currentTask)
            }
          })
        }

        dispatch(replaceItems(tasksToRemove, tasksToUpdate))
      }

      try {
        await taskApi.del(task.id, recurrenceOption)
        RealTime.publishMessage(task.id, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { task }))
      }
    },
    [dispatch, findItemById, tasksById]
  )

  const addRecurrenceToSingleTask = useCallback(
    (task) => {
      const recurringTask = utils.recurrency.tasks.computeRecurringTask(
        session.id,
        task,
        task.recurrencyInformation.recurrencyDetails
      )

      if (!recurringTask) return null

      const tasks = utils.recurrency.tasks.scheduleNextTasksForDay(task.when.date, recurringTask)

      if (!tasks) return null

      dispatch(addItems(tasks))
      return { recurringTask, tasks }
    },
    [dispatch, session.id]
  )

  const showTaskCreationAlert = useCallback(() => {
    if (taskCreationAlertTimeout.current) {
      window.clearTimeout(taskCreationAlertTimeout.current)
      setIsTaskCreationAlertShown(false)
    }
    setIsTaskCreationAlertShown(() => {
      taskCreationAlertTimeout.current = window.setTimeout(() => setIsTaskCreationAlertShown(false), 1800)
      return true
    })
  }, [])

  const createRecurrentTask = useCallback(
    async ({ context, dontShowCreationAlert, task }) => {
      const result = addRecurrenceToSingleTask(task)

      if (!result) return

      const { recurringTask, tasks } = result
      const [firstInstance] = tasks

      if (context?.componentSource === 'inlineAddTask' && !dontShowCreationAlert) {
        showTaskCreationAlert()
      }

      try {
        task.creationTime = recurringTask.creationTime
        const { id, ...dbTask } = task
        const response = await taskApi.create(dbTask, context)

        if (!response.instances) return response

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const [firstResponseInstance] = response.instances

        if (response.instances.length !== tasks.length || firstResponseInstance.id !== firstInstance.id) {
          dispatch(replaceItems(tasks, response.instances))
        }

        return response
      } catch (err: any) {
        dispatch(handleAPIError(err, { task }))
        return { error: err?.data?.code }
      }
    },
    [addRecurrenceToSingleTask, dispatch, showTaskCreationAlert]
  )

  const createSingleInstanceTask = useCallback(
    async ({ context, dontShowCreationAlert, task }) => {
      let sprint

      if (task.sprintInfo && !context?.fromOnboarding) {
        sprint = clone(findItemById(task.sprintInfo?.id))

        if (!sprint) {
          console.warn('-- sprint not found')
        }
      }

      if (!task.id) {
        task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
      }

      if (sprint) {
        sprint.tasks = (sprint.tasks || []).concat(task)
        dispatch(updateItem(sprint))
      } else {
        dispatch(addItem(task))
      }

      if (context?.componentSource === 'inlineAddTask' && !dontShowCreationAlert) {
        showTaskCreationAlert()
      }

      try {
        const { id, ...dbTask } = task
        const response = await taskApi.create(dbTask, context)

        if (!response.task) return

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const createdTask = response.task

        if (createdTask.id !== task.id) {
          if (sprint) {
            const idx = (sprint.tasks || []).findIndex((sprintTask) => sprintTask.id === task.id)
            if (idx > -1) {
              sprint = produce(sprint, (draft) => {
                draft.tasks[idx] = createdTask
              })
              dispatch(updateItem(sprint))
            }
          } else {
            dispatch(replaceItem(task, createdTask))
          }
        }

        // we need to repair the ranks
        utils.resolve.awaitOnce(`taskApi.repairActive`, taskApi.repairActive, 5000)

        return response
      } catch (err: any) {
        if (!err.cancel) {
          dispatch(handleAPIError(err, { task }))
        }
        return { error: err?.data?.code }
      }
    },
    [dispatch, findItemById, session.id, showTaskCreationAlert]
  )

  const createInlineTask = useCallback(
    async (taskData) => {
      const { task } = taskData
      let result

      if (task.recurrencyInformation?.recurrencyDetails) {
        result = await createRecurrentTask(taskData)
      } else {
        result = await createSingleInstanceTask(taskData)
      }

      return result
    },
    [createRecurrentTask, createSingleInstanceTask]
  )

  const getFocusedTaskId = useCallback(() => {
    return !session.hideFocusedTask && utils.session.getFSTaskIdFromSession(session)
  }, [session])

  const updateLocalTaskFromEdit = useCallback(
    async (newTask, oldTask) => {
      const updatedItems = dispatch(updateItem(newTask))
      const oldSprint = updatedItems.sprints[oldTask?.sprintInfo?.id]

      if (oldSprint) {
        const now = moment()

        if (utils.sprint.shouldCompleteSprint(oldSprint, now)) {
          const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(oldSprint)

          const updatedOldSprint = produce(oldSprint, (draft) => {
            draft.completionTime = completionTime
            draft.estimatedTime = estimatedTime
          })

          dispatch(updateItem(updatedOldSprint))
        }
      }
    },
    [dispatch]
  )

  const updateTaskAndRemoveRecurrencyOfSeries = useCallback(
    (instances, newTask, oldTask) => {
      const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)

      if (!splittedInstances) return null

      const { before, after } = splittedInstances

      const updatedInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })

        draft.push(newTask)
      })

      dispatch(updateItems(updatedInstances))
      dispatch(removeItems(after))
      updateLocalTaskFromEdit(newTask, oldTask)
    },
    [dispatch, updateLocalTaskFromEdit]
  )

  const updateTaskAndRecurrencyInfoOfSeries = useCallback(
    (instances, newTask) => {
      const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)

      if (!splittedInstances) return null

      const { before, instance, after } = splittedInstances

      const updatedBeforeInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })
      })

      dispatch(replaceItems(before, updatedBeforeInstances))

      const recurringTask = utils.recurrency.tasks.computeRecurringTask(
        session.id,
        newTask,
        newTask.recurrencyInformation.recurrencyDetails
      )

      if (!recurringTask) return null

      const tasks = utils.recurrency.tasks.scheduleNextTasksForDay(newTask.when.date, recurringTask)

      if (!tasks) return null

      const oldRecItems = [instance, ...after]
      dispatch(replaceItems(oldRecItems, tasks))

      return { createdRecurringTask: recurringTask, createdInstances: tasks }
    },
    [dispatch, session.id]
  )

  const updateTaskWhenRecurrencyDetailsAreSame = useCallback(
    (instances, newTask: Task, oldTask: Task, recurrenceOption: InstanceOption) => {
      let hasWhenDateChanged = newTask.when?.date !== oldTask.when?.date

      if (newTask.pin?.time) {
        hasWhenDateChanged =
          moment(newTask.pin.time).format('YYYY-MM-DD') !== moment(oldTask.when?.date).format('YYYY-MM-DD')
      }

      switch (true) {
        case recurrenceOption === InstanceOptions.Single: {
          updateLocalTaskFromEdit(newTask, oldTask)
          return
        }

        case hasWhenDateChanged: {
          return updateTaskAndRecurrencyInfoOfSeries(instances, newTask)
        }

        case recurrenceOption === InstanceOptions.All || recurrenceOption === InstanceOptions.Next: {
          if (utils.task.areTaskAttributesNotEqual(newTask, oldTask)) {
            let instancesToUpdate = instances

            if (recurrenceOption === InstanceOptions.Next) {
              const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)
              instancesToUpdate = splittedInstances.after
            }

            let newPinMoment
            if (newTask.pin?.time) {
              newPinMoment = moment(newTask.pin.time)
            }

            const updatedInstances = produce(instancesToUpdate, (draft) => {
              draft.forEach((instance) => {
                if (instance.id === newTask.id) return

                instance.title = newTask.title
                instance.estimatedTime = newTask.estimatedTime
                instance.projects = newTask.projects
                instance.projectsId = newTask.projectsId
                instance.projectsRank = newTask.projectsRank
                instance.tags = newTask.tags
                instance.tagsId = newTask.tagsId
                instance.tagsRank = newTask.tagsRank
                instance.urlsInfo = newTask.urlsInfo

                if (newPinMoment && !instance.sprintInfo && instance.pin?.time) {
                  instance.pin = {
                    time: moment(instance.pin.time).set({
                      hour: newPinMoment.hour(),
                      minute: newPinMoment.minute(),
                      second: newPinMoment.second(),
                    }),
                  }
                } else {
                  delete instance.pin
                }
              })
            })

            dispatch(updateItems(updatedInstances))
            updateLocalTaskFromEdit(newTask, oldTask)
          } else {
            updateLocalTaskFromEdit(newTask, oldTask)
          }
          break
        }

        // single option
        default: {
          updateLocalTaskFromEdit(newTask, oldTask)
        }
      }
    },
    [dispatch, updateLocalTaskFromEdit, updateTaskAndRecurrencyInfoOfSeries]
  )

  const updateRecurringTask = useCallback(
    (newTask: Task, oldTask: Task, recurrenceOption: InstanceOption) => {
      const instances = getAllInstancesOfRecTask(tasksById, findItemById, utils.task.getRecTaskId(oldTask), true)

      switch (true) {
        case !newTask.recurrencyInformation: {
          return updateTaskAndRemoveRecurrencyOfSeries(instances, newTask, oldTask)
        }

        case utils.recurrency.options.isRecurrenceEqual(
          oldTask.recurrencyInformation?.recurrencyDetails,
          newTask.recurrencyInformation?.recurrencyDetails
        ): {
          return updateTaskWhenRecurrencyDetailsAreSame(instances, newTask, oldTask, recurrenceOption)
        }

        default: {
          return updateTaskAndRecurrencyInfoOfSeries(instances, newTask)
        }
      }
    },
    [
      findItemById,
      tasksById,
      updateTaskAndRecurrencyInfoOfSeries,
      updateTaskAndRemoveRecurrencyOfSeries,
      updateTaskWhenRecurrencyDetailsAreSame,
    ]
  )

  const handleTaskSave = useCallback(
    async (newTask: Task, oldTask: Task, recurrenceOption?: InstanceOption, { onRecurrenceComputed } = {}) => {
      let createdInstances
      switch (true) {
        case !!oldTask.recurrencyInformation: {
          const result = updateRecurringTask(newTask, oldTask, recurrenceOption as InstanceOption)
          if (!!result) {
            // to make sure the new id generated is the same as the one the front end generated
            newTask = produce(newTask, (draft) => {
              draft.creationTime = result?.createdRecurringTask.creationTime
              draft.recurrencyInformation.recurrencyDetails = result?.createdRecurringTask.recurrencyDetails
            })
            createdInstances = result?.createdInstances
          }

          onRecurrenceComputed?.(result)
          break
        }

        case !oldTask.recurrencyInformation && !!newTask.recurrencyInformation: {
          dispatch(removeItem(oldTask))
          const result = addRecurrenceToSingleTask(newTask)

          if (result) {
            const { recurringTask: createdRecurringTask, tasks } = result
            // to make sure the new id generated is the same as the one the front end generated
            newTask.creationTime = createdRecurringTask.creationTime
            createdInstances = tasks
          }

          break
        }

        default: {
          updateLocalTaskFromEdit(newTask, oldTask)
          break
        }
      }

      try {
        const out = await taskApi.putFullTask(newTask, recurrenceOption)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (!out?.instances) return

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== createdInstances?.length || firstResponseInstance.id !== createdInstances[0].id) {
          dispatch(replaceItems(createdInstances, out.instances))
        }
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [addRecurrenceToSingleTask, dispatch, updateLocalTaskFromEdit, updateRecurringTask]
  )

  const saveTask = useCallback(
    (
      updatedTask: Task,
      { onCancel, onRecurrenceComputed }: { onCancel?: () => void; onRecurrenceComputed?: () => void } = {}
    ) => {
      const newTask = clone(updatedTask)
      const oldTask = findItemById(updatedTask.id)

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      const isOldTaskRecurrent = utils.task.isRecurrent(oldTask)
      const isNewTaskRecurrent = utils.task.isRecurrent(newTask)
      const taskAttributesChanged = utils.task.areTaskAttributesNotEqual(newTask, oldTask)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldTask.recurrencyInformation?.recurrencyDetails,
        newTask.recurrencyInformation?.recurrencyDetails
      )

      if (!isOldTaskRecurrent && !isNewTaskRecurrent && !taskAttributesChanged) return

      if (isOldTaskRecurrent && isNewTaskRecurrent) {
        if (newTask.sprintInfo) {
          const haveTaskSimpleAttributesChanged = utils.task.areTaskSimpleAttributesNotEqual(newTask, oldTask)
          if (haveTaskSimpleAttributesChanged) {
            recurringItemPopup(
              {
                hideAllOption: true,
                hideSingleOption: true,
                hideTitle: true,
                noOptionsMessageText: translations.task.recurrencyPanel.noOptionsSingleTask,
                noOptionsValue: InstanceOptions.Single,
              },
              {
                onConfirmed: (recurrenceOption) => handleTaskSave(newTask, oldTask, recurrenceOption),
                onCancelled: onCancel,
              }
            )

            return
          }
        }

        if (!hasRecurrenceChanged && !taskAttributesChanged) return

        const hasWhenDateChanged = newTask.when.date !== oldTask.when.date
        const hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        const hideTitle = hideAllOption && hasRecurrenceChanged
        recurringItemPopup(
          {
            hideAllOption,
            hideSingleOption: hasRecurrenceChanged,
            hideTitle,
            title: translations.task.recurrencyPanel.edit.prompt,
          },
          {
            onConfirmed: (recurrenceOption) =>
              handleTaskSave(newTask, oldTask, recurrenceOption, { onRecurrenceComputed }),
            onCancelled: onCancel,
          }
        )
      } else {
        handleTaskSave(newTask, oldTask, undefined, undefined)
      }
    },
    [findItemById, handleTaskSave, recurringItemPopup]
  )

  const startFsAndCreateTask = useCallback(
    async ({
      context,
      taskData = models.task({
        title: '',
        when: { date: moment().format('YYYY-MM-DD') },
        to: utils.user.computeUserFromSessionUser(session.user),
      }),
    }) => {
      const creatingTask = utils.ids.addIdToItem(taskData, models.item.type.TASK, session.id)

      dispatch(addItem(creatingTask))

      await dispatch(
        createTaskAndFs(creatingTask, context, (createdTask) => {
          if (createdTask.id !== creatingTask.id) {
            dispatch(replaceItem(creatingTask, createdTask))
          }
        })
      )
    },
    [dispatch, session.user, session.id]
  )

  const onChangeTaskParam = useCallback(
    (
      { taskId, task }: { taskId?: string; task: Task },
      { paramName, value, method = 'change' },
      extraParams,
      forceRequest: boolean
    ) => {
      if (!task) {
        let taskFromList = findItemById(taskId)

        if (!taskFromList) {
          console.warn('-- task not found')
          return
        }

        task = taskFromList
      } else {
        taskId = task.id
      }

      const newTask = utils.task.computeTaskOnChange(
        task,
        {
          paramName,
          value,
          method,
        },
        extraParams
      )

      dispatch(updateItem(newTask))

      if (task || forceRequest) {
        sendPatchRequest({ extraParams, method, paramName, task, value })
      }
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const onTitleChange = useCallback(
    (title: string, task: Task) => {
      const focusedTaskId = getFocusedTaskId()

      if (focusedTaskId && task.id === focusedTaskId) {
        dispatch(updateFocusSessionTitle(title))
      }

      onChangeTaskParam({ task }, { paramName: 'title', value: title }, null, true)
    },
    [dispatch, getFocusedTaskId, onChangeTaskParam]
  )

  const onClickDelete = useCallback(
    (taskId: string, callback?: () => void) => {
      const task = findItemById(taskId)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      taskDeletePopUp(task, {
        onConfirmed: (recurrenceOption) => {
          deleteTask(taskId, recurrenceOption)
          callback?.()
        },
      })
    },
    [deleteTask, findItemById, taskDeletePopUp]
  )

  return {
    archiveTask,
    completeTask,
    completeTaskFromFS,
    createInlineTask,
    deleteTask,
    getFocusedTaskId,
    handleTaskSave,
    isTaskCreationAlertShown,
    onClickDelete,
    onTitleChange,
    saveTask,
    showTaskCreationAlert,
    startFsAndCreateTask,
    uncompleteTask,
  }
}
