import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { translations } from 'gipsy-misc'
import { InstanceOption, Sprint, Task } from 'gipsy-misc/types'

import ConfirmPanel from 'features/popup/components/confirmPanel'
import Loading from 'features/popup/components/loading'
import RecurringItemPanel from 'features/popup/components/recurrentItemPanel'
import { closePopup as _closePopup, openPopup } from 'store/popup/actions'

type PopupCallbacks = { onCancelled?: (...args: any) => any; onConfirmed?: (...args: any) => any }

export default function usePopupsHooks() {
  const dispatch = useDispatch()

  const closePopup = useCallback(() => {
    dispatch(_closePopup())
  }, [dispatch])

  const deletionPopup = useCallback(
    (
      {
        cancelLabel,
        confirmLabel,
        logo = 'sad',
        text,
        title,
      }: { cancelLabel: string; confirmLabel: string; logo?: string; text: string; title: string },
      { onCancelled, onConfirmed }: PopupCallbacks = {}
    ) => {
      const onConfirm = () => {
        onConfirmed?.()
        closePopup()
      }

      const onCancel = () => {
        onCancelled?.()
        closePopup()
      }

      dispatch(
        openPopup({
          title,
          centeredTitle: true,
          logo,
          component: (
            <ConfirmPanel
              cancelLabel={cancelLabel}
              confirmLabel={confirmLabel}
              onCancel={onCancel}
              onConfirm={onConfirm}
              text={text}
            />
          ),
        })
      )
    },
    [closePopup, dispatch]
  )

  const addedLinksFSDeletePopup = useCallback(
    (callbacks: PopupCallbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.focussession.deleteOnEditConfirmation.cancel,
          confirmLabel: translations.focussession.deleteOnEditConfirmation.consent,
          text: translations.focussession.deleteOnEditConfirmation.prompt,
          title: translations.focussession.deleteOnEditConfirmation.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const focusSessionDeletePopup = useCallback(
    (callbacks: PopupCallbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.general.noKeep,
          confirmLabel: translations.general.yesPlease,
          text: translations.focussession.delete.prompt,
          title: translations.focussession.delete.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const openLoadingPopup = useCallback(() => {
    dispatch(
      openPopup({
        centeredTitle: true,
        component: <Loading text={'Stay still! We are generating your instances'} />,
        hideLogo: true,
      })
    )
  }, [dispatch])

  const recurringItemPopup = useCallback(
    (
      {
        forSprint,
        hideAllOption,
        hideSingleOption,
        hideTitle,
        noOptionsMessageText,
        noOptionsValue,
        title,
      }: {
        forSprint?: boolean
        hideAllOption?: boolean
        hideSingleOption?: boolean
        hideTitle?: boolean
        noOptionsMessageText?: string
        noOptionsValue?: InstanceOption
        title?: string
      } = {},
      { onCancelled, onConfirmed }: PopupCallbacks = {}
    ) => {
      const onConfirm = (optionChecked) => {
        closePopup()
        onConfirmed?.(optionChecked)
      }

      const onCancel = () => {
        closePopup()
        onCancelled?.()
      }

      dispatch(
        openPopup({
          centeredTitle: true,
          component: (
            <RecurringItemPanel
              forSprint={forSprint}
              hideAllOption={hideAllOption}
              hideSingleOption={hideSingleOption}
              noOptionsMessageText={noOptionsMessageText}
              noOptionsValue={noOptionsValue}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          ),
          hideTitle,
          logo: 'happy',
          title,
          onClose: onCancel,
        })
      )
    },
    [closePopup, dispatch]
  )

  const sprintDeletePopup = useCallback(
    (sprint: Sprint, callbacks: PopupCallbacks) => {
      if (sprint.recurrencyInformation) {
        recurringItemPopup({ forSprint: true, title: translations.sprint.recurrencyPanel.delete.prompt }, callbacks)
      } else {
        deletionPopup(
          {
            cancelLabel: translations.sprint.delete.deny,
            confirmLabel: translations.sprint.delete.consent,
            text: translations.sprint.delete.prompt,
            title: translations.sprint.delete.prompt,
          },
          callbacks
        )
      }
    },
    [deletionPopup, recurringItemPopup]
  )

  const taskDeletePopUp = useCallback(
    (task: Task, callbacks: PopupCallbacks) => {
      if (task.recurrencyInformation) {
        recurringItemPopup({ title: translations.task.recurrencyPanel.delete.prompt }, callbacks)
      } else {
        deletionPopup(
          {
            cancelLabel: translations.taskPanel.delete.deny,
            confirmLabel: translations.taskPanel.delete.consent,
            text: translations.taskPanel.delete.prompt,
            title: translations.taskPanel.delete.prompt,
          },
          callbacks
        )
      }
    },
    [deletionPopup, recurringItemPopup]
  )

  return {
    addedLinksFSDeletePopup,
    closePopup,
    deletionPopup,
    focusSessionDeletePopup,
    openLoadingPopup,
    recurringItemPopup,
    sprintDeletePopup,
    taskDeletePopUp,
  }
}
