import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { variables } from 'gipsy-misc'
import { FocusedLineCompressed, NewLinksPopup } from 'gipsy-ui'

import usePageActions from 'features/hooks/usePageActions'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import { innerLeftPadding, innerRightPadding } from 'features/layout/pages'
import { pauseFocusSession } from 'store/session/actions'
import { showSnackbar } from 'store/snackbar/actions'

function HomeBaseFocusedLine({ containerItemId, onCompleteTaskFromFS, ...props }) {
  const {
    completeTaskFromFS,
    handleCompletedSession,
    onClickFocusSession,
    onClickDeleteFocusSession,
    onTitleChange,
    popShortcutsGroup: unregisterShortcuts,
    pushShortcutsGroup: registerShortcuts,
    updateFocusSession,
  } = usePageActions()
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session)

  const onPauseFocusSession = useCallback((...args) => dispatch(pauseFocusSession(...args)), [dispatch])
  const _showSnackbar = useCallback((...args) => dispatch(showSnackbar(...args)), [dispatch])

  const handleTaskCompletion = useCallback(
    (...args) => {
      completeTaskFromFS(...args)
      onCompleteTaskFromFS?.()
    },
    [completeTaskFromFS, onCompleteTaskFromFS]
  )

  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)
  const showFocusSession = !!session?.focusSession?.taskId
  const showNewLinksPopup = !!session?.completedSession

  return (
    <Container data-item-id={containerItemId}>
      <AnimatePresence>
        {showFocusSession && (
          <motion.div
            animate={'expanded'}
            exit={'collapsed'}
            key='focused-line-wrapper'
            initial={'collapsed'}
            variants={fsWrapperVariants}>
            <FocusedLineCompressed
              {...props}
              innerLeftPadding={innerLeftPadding}
              innerRightPadding={innerRightPadding}
              key='focused-line'
              onClickFocusSession={onClickFocusSession}
              onCompleteFromFS={handleTaskCompletion}
              onDeleteFocusSession={onClickDeleteFocusSession}
              onPauseFocusSession={onPauseFocusSession}
              onTitleChange={onTitleChange}
              onUpdateFocusSession={updateFocusSession}
              registerShortcuts={registerShortcuts}
              session={session}
              showSnackbar={_showSnackbar}
              unregisterShortcuts={unregisterShortcuts}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {portalNode &&
        createPortal(
          <AnimatePresence>
            {showNewLinksPopup && (
              <PopupWrapper
                animate={'fadeIn'}
                exit={'fadeOut'}
                initial={'initial'}
                key='new-links'
                variants={newLinksVariants}>
                <NewLinksPopup
                  onOkClicked={handleCompletedSession}
                  registerShortcuts={registerShortcuts}
                  session={session?.completedSession}
                  unregisterShortcuts={unregisterShortcuts}
                />
              </PopupWrapper>
            )}
          </AnimatePresence>,
          portalNode
        )}
    </Container>
  )
}

const Container = styled(motion.div)``

Container.displayName = 'Container'

const fsWrapperVariants = {
  collapsed: {
    height: 0,
    overflow: 'hidden',
    transition: {
      duration: 0.4,
    },
  },
  expanded: {
    height: 'auto',
    transition: {
      duration: 0.4,
    },
    transitionEnd: {
      overflow: 'visible',
    },
  },
}

const newLinksVariants = {
  fadeIn: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
  fadeOut: {
    opacity: 0,
  },
  initial: {
    left: 0,
    opacity: 0,
    top: 0,
  },
}

const PopupWrapper = styled(motion.div)`
  position: fixed;
  z-index: ${variables.zIndex.layoutBlurLayer};
`

PopupWrapper.displayName = 'PopupWrapper'

export default HomeBaseFocusedLine
