import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clone from 'lodash/clone'

import { focussession as focussessionApi } from 'gipsy-api'
import { utils } from 'gipsy-misc'
import { FocusSession, Task } from 'gipsy-misc/types'

import { handleAPIError } from 'store/app/actions'
import { setHighlightedEventId, updateCalendarDate } from 'store/calendar/actions'
import { updateItem } from 'store/items/actions'
import { getFindItemByIdFn } from 'store/items/selectors'
import { handleCompletedSession as _handleCompletedSession, updateFocusSessionTask } from 'store/session/actions'

export default function useFocusSessionHooks({
  addedLinksFSDeletePopup,
  focusSessionDeletePopup,
  removeItemFromCalendar,
  updateCalendarItem,
}) {
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)

  const deleteFocusSession = useCallback(
    async (focusSession: FocusSession) => {
      const task: Task = findItemById(focusSession.taskId)
      removeItemFromCalendar(focusSession.id)

      if (!task) {
        console.warn('-- task not found')
      } else {
        const updatedTask = clone(task)
        updatedTask.focusSessions = updatedTask.focusSessions.filter((fs) => fs.id !== focusSession.id)
        dispatch(updateItem(updatedTask))

        if (task && session.focusSession?.taskId === focusSession.taskId) {
          dispatch(updateFocusSessionTask(updatedTask))
        }
      }

      try {
        await focussessionApi.del(focusSession.id)
      } catch (err) {
        dispatch(handleAPIError(err, { focusSession }))
      }
    },
    [dispatch, findItemById, removeItemFromCalendar, session.focusSession?.taskId]
  )

  const updateTaskWithCallback = useCallback(
    (taskId: string, callback: (task: Task) => any) => {
      const task: Task = clone(findItemById(taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = callback(task)
      dispatch(updateItem(updatedTask))
    },
    [dispatch, findItemById]
  )

  const handleCompletedSession = useCallback(
    async (sessionDiffs) => {
      const completeSessionAndDiscard = (discardSession, focusSessionsUpdateFunc) => {
        dispatch(
          _handleCompletedSession({ ...sessionDiffs, discardSession }, (updated) => {
            const { completedSession, completedSessionTask } = updated
            updateTaskWithCallback(completedSessionTask.id, (task) => ({
              ...task,
              focusSessions: focusSessionsUpdateFunc(task.focusSessions || [], completedSession),
              title: completedSessionTask.title,
              urlsInfo: completedSessionTask.urlsInfo,
            }))
          })
        )
      }

      const spentTimeNS = utils.focussession.getSpentTimeInNanoSeconds(sessionDiffs.updatedSession)
      const wasSessionDiscarded = !!sessionDiffs.updatedSession?.discarded

      const removeFS = (focusSessions, completedSession) => {
        return focusSessions.filter((fs) => fs.id !== completedSession.id)
      }

      const updateFS = (focusSessions, completedSession) => {
        if (focusSessions.length === 0) {
          return focusSessions.concat([completedSession])
        }

        const updatedSessions = [...focusSessions]
        const toUpdateIndex = focusSessions.findIndex((fs) => fs.id === completedSession.id)

        if (toUpdateIndex !== -1) {
          updatedSessions[toUpdateIndex] = completedSession
        }

        return updatedSessions
      }

      const noop = (fs) => fs

      if (!wasSessionDiscarded && spentTimeNS < utils.focussession.minDurationFSNS) {
        addedLinksFSDeletePopup({
          onConfirmed: () => {
            completeSessionAndDiscard(true, removeFS)
          },
        })
      } else {
        completeSessionAndDiscard(false, wasSessionDiscarded ? noop : updateFS)
      }
    },
    [addedLinksFSDeletePopup, dispatch, updateTaskWithCallback]
  )

  const onClickFocusSession = useCallback(
    (fs: FocusSession) => {
      dispatch(setHighlightedEventId(fs.id))
      dispatch(updateCalendarDate(fs.startTime))
    },
    [dispatch]
  )

  const onClickDeleteFocusSession = useCallback(
    ({ focusSession, callback }: { focusSession: FocusSession; callback?: () => void }) => {
      focusSessionDeletePopup({
        onConfirmed: () => {
          deleteFocusSession(focusSession)
          callback?.()
        },
        onCancelled: () => {
          callback?.()
        },
      })
    },
    [deleteFocusSession, focusSessionDeletePopup]
  )

  const updateFocusSession = useCallback(
    (updatedFocusSession: FocusSession) => {
      updateCalendarItem(updatedFocusSession.id, updatedFocusSession)

      const indexInFocusedLine =
        updatedFocusSession.taskId === session?.focusSession?.taskId
          ? session?.focusSession?.task?.focusSessions?.findIndex?.((fs) => fs.id === updatedFocusSession.id)
          : undefined

      if (indexInFocusedLine !== undefined && indexInFocusedLine !== -1) {
        const fsTask = { ...session.focusSession.task }
        const fsTaskSessions = [...fsTask.focusSessions]
        fsTaskSessions[indexInFocusedLine] = updatedFocusSession
        fsTask.focusSessions = fsTaskSessions
        dispatch(updateFocusSessionTask(fsTask))
      }

      const task: Task = clone(findItemById(updatedFocusSession.taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const idx = task.focusSessions?.findIndex((fs) => fs.id === updatedFocusSession.id)

      if (idx === undefined || idx < 0) {
        console.warn('-- focus session not found')
        return
      }

      task.focusSessions = [...task.focusSessions!]
      task.focusSessions[idx] = updatedFocusSession
      dispatch(updateItem(task))

      // TODO: backend update here, decouple from FS's popup
    },
    [dispatch, findItemById, session.focusSession?.task, session.focusSession?.taskId, updateCalendarItem]
  )

  return {
    deleteFocusSession,
    handleCompletedSession,
    onClickDeleteFocusSession,
    onClickFocusSession,
    updateFocusSession,
  }
}
