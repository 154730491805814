import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'
import clone from 'lodash/clone'
import moment from 'moment'

import { sprint as sprintApi, task as taskApi } from 'gipsy-api'
import { models, styles, utils } from 'gipsy-misc'
import { Sprint, Task } from 'gipsy-misc/types'

import { handleAPIError } from 'store/app/actions'
import { addItem, removeItems, updateItem, updateItems } from 'store/items/actions'
import { getFindItemByIdFn } from 'store/items/selectors'
import { toggleCompleteTaskRequest } from 'store/task/actions'

const { InstanceOptions } = models.recurrency

type Item = Sprint | Task

export default function useBatchHooks({ addItemToCalendar }) {
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))

  const addTasksToSprint = useCallback(
    (sprintId, taskIds) => {
      const sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }

      let currentRank = sprint.tasks?.length || 0
      const previousSprintsMap = {}
      const previousTasks: Task[] = []
      const updatedTasks = taskIds.reduce((currentTasks, taskId) => {
        const task = findItemById(taskId)

        if (!task || task.type !== models.item.type.TASK) return currentTasks

        previousTasks.push(task)

        if (task.sprintInfo) {
          const prevSprint = findItemById(task.sprintInfo.id)

          if (prevSprint) {
            previousSprintsMap[prevSprint.id] = prevSprint
          }
        }

        currentTasks.push(
          utils.task.computeTaskOnChange(task, {
            method: undefined,
            paramName: 'sprintInfo',
            value: {
              id: sprint.id,
              title: sprint.title,
              estimatedTime: sprint.estimatedTime,
              pin: sprint.pin,
              rank: ++currentRank,
            },
          })
        )
        return currentTasks
      }, [])

      dispatch(updateItems(updatedTasks))

      return {
        saveToBackend: () => {
          updatedTasks.forEach((task) => {
            sprintApi
              .dragAndDropTasks({
                taskId: task.id,
                sprintId: sprint.id,
                toRank: task.sprintInfo.rank,
              })
              .catch((err) => {
                dispatch(handleAPIError(err, { sprint, task }))
              })
          })
        },
        undoAction: () => {
          dispatch(updateItems([...previousTasks, ...Object.values(previousSprintsMap)]))
        },
      }
    },
    [dispatch, findItemById]
  )

  const completeTasks = useCallback(
    (taskIds) => {
      const toUpdateSprintsById = {}
      const toCompleteTasks: Task[] = []

      taskIds.forEach((id) => {
        const oldTask = findItemById(id)
        if (!oldTask || oldTask.type !== models.item.type.TASK || oldTask.completed) return

        const completedTask = utils.task.updateTaskWhenComplete(oldTask)
        toCompleteTasks.push(completedTask)
        const sprintId = oldTask.sprintInfo?.id

        if (sprintId) {
          const sprint = toUpdateSprintsById[sprintId] || clone(findItemById(sprintId))

          if (sprint) {
            const now = moment()
            sprint.tasks = sprint.tasks?.filter((completedTask) => completedTask.id !== id)

            if (utils.sprint.shouldCompleteSprint(sprint, now)) {
              const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
              sprint.completionTime = completionTime
              sprint.estimatedTime = estimatedTime
            }

            toUpdateSprintsById[sprintId] = sprint
          }

          dispatch(addItem(completedTask))
        } else {
          dispatch(updateItem(completedTask))
        }
      })

      dispatch(updateItems(Object.values(toUpdateSprintsById)))

      toCompleteTasks.forEach((task, index) => {
        const updatedTask = clone(task)
        dispatch(toggleCompleteTaskRequest(task.id, 1, undefined, { hideMessage: index !== 0 })).then((out) => {
          if (out?.createdFocusSession) {
            addItemToCalendar(
              { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
              styles.colors.focusSessionFill
            )

            updatedTask.focusSessions = (updatedTask.focusSessions || []).concat([out.createdFocusSession])
            dispatch(updateItem(updatedTask))
          }
        })
      })
    },
    [addItemToCalendar, dispatch, findItemById]
  )

  const deleteItems = useCallback(
    (itemIds) => {
      const sprintsIdsToRemove: string[] = []
      const sprintTasksToRemoveMap = {}
      const tasksToRemoveFromState: Task[] = []
      const tasksToRemove: Task[] = []

      itemIds.forEach((id) => {
        const item = findItemById(id)

        if (!item) return

        if (item.type === models.item.type.TASK) {
          tasksToRemoveFromState.push(item)

          if (!item.sprintInfo) {
            tasksToRemove.push(item)
            return
          }

          const sprintTasks = sprintTasksToRemoveMap[item.sprintInfo.id] || []
          sprintTasks.push(item.id)
          sprintTasksToRemoveMap[item.sprintInfo.id] = sprintTasks
        } else if (item.type === models.item.type.SPRINT) {
          sprintsIdsToRemove.push(id)
        }
      })

      const updatedItems = dispatch(removeItems(tasksToRemoveFromState))
      const sprintsToRemove: Sprint[] = []
      sprintsIdsToRemove.forEach((sprintId) => {
        if (updatedItems.sprints[sprintId]) {
          sprintsToRemove.push(updatedItems.sprints[sprintId])
        }
      })

      dispatch(removeItems(sprintsToRemove))

      tasksToRemove.forEach((task) => {
        taskApi.del(task.id, InstanceOptions.Single).catch((err) => {
          dispatch(handleAPIError(err, { task }))
        })
      })

      sprintsToRemove.forEach((sprint) => {
        const tasksToDeleteIds = sprintTasksToRemoveMap[sprint.id] || []
        delete sprintTasksToRemoveMap[sprint.id]

        sprintApi.del(sprint.id, InstanceOptions.Single, tasksToDeleteIds).catch((err) => {
          dispatch(handleAPIError(err, { sprint }))
        })
      })

      const remainingSprintTasksToRemove = Object.keys(sprintTasksToRemoveMap)
      if (remainingSprintTasksToRemove.length !== 0) {
        remainingSprintTasksToRemove.forEach((sprintId) => {
          const sprintTasks = sprintTasksToRemoveMap[sprintId]

          sprintTasks.forEach((taskId) => {
            taskApi.del(taskId, InstanceOptions.Single).catch((err) => {
              dispatch(handleAPIError(err, { taskId, sprintId }))
            })
          })
        })
      }
    },
    [dispatch, findItemById]
  )

  const editTasks = useCallback(
    (taskIds, editFn = (t) => t) => {
      const tasksToUpdate: Task[] = []
      const previousTasks: Task[] = []

      taskIds.forEach((id) => {
        const item: Task = findItemById(id)

        if (!item || item.type !== models.item.type.TASK) return

        previousTasks.push(item)
        tasksToUpdate.push(
          produce(item, (draft) => {
            return editFn(draft)
          })
        )
      })

      dispatch(updateItems(tasksToUpdate))

      return {
        saveToBackend: () => {
          tasksToUpdate.forEach((task) => {
            taskApi.putFullTask(task, InstanceOptions.Single)
          })
        },
        undoAction: () => {
          dispatch(updateItems(previousTasks))
        },
      }
    },
    [dispatch, findItemById]
  )

  const rescheduleItems = useCallback(
    (itemIds, newDate) => {
      const itemsToUpdate: Item[] = []
      const previousItems: Item[] = []
      const newDateMoment = moment(newDate)

      itemIds.forEach((id) => {
        const item: Item = findItemById(id)

        if (!item) return

        if (item && item.type === models.item.type.SPRINT) {
          const updatedSprint = produce(item, (draft: Sprint) => {
            const updatedPin = moment(draft.pin.time)
            updatedPin.date(newDateMoment.date())
            updatedPin.month(newDateMoment.month())
            updatedPin.year(newDateMoment.year())
            draft.pin.time = updatedPin.format()
            draft.when.date = newDateMoment.format('YYYY-MM-DD')
          })

          previousItems.push(item)
          itemsToUpdate.push(updatedSprint)
        } else if (item.type === models.item.type.TASK) {
          if ((item as Task).sprintInfo) return

          const updatedTask = produce(item, (draft: Task) => {
            if (draft.pin?.time) {
              const updatedPin = moment(draft.pin.time)
              updatedPin.date(newDateMoment.date())
              updatedPin.month(newDateMoment.month())
              updatedPin.year(newDateMoment.year())
              draft.pin.time = updatedPin.format()
            }

            draft.when!.date = newDateMoment.format('YYYY-MM-DD')
          })

          previousItems.push(item)
          itemsToUpdate.push(updatedTask)
        }
      })

      dispatch(updateItems(itemsToUpdate))

      return {
        saveToBackend: () => {
          itemsToUpdate.forEach((item) => {
            if (item.type === models.item.type.SPRINT) {
              sprintApi.editWithTasks(item, { recurrenceOption: InstanceOptions.Single })
            } else {
              taskApi.putFullTask(item, InstanceOptions.Single)
            }
          })
        },
        undoAction: () => {
          dispatch(updateItems(previousItems))
        },
      }
    },
    [dispatch, findItemById]
  )

  return {
    addTasksToSprint,
    completeTasks,
    deleteItems,
    editTasks,
    rescheduleItems,
  }
}
