import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { produce } from 'immer'
import moment from 'moment'

import { mixpanel as mixpanelApi, todayview as todayviewApi } from 'gipsy-api'
import { mixpanel, models, translations, utils } from 'gipsy-misc'

import { batchActionTypes } from 'features/batchActionsBar'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import useBatchSelectionHandler from 'features/hooks/useBatchSelectionHandler'
import { initGroupList } from 'features/hooks/useBatchSelectionHandler/utils'
import usePageActions from 'features/hooks/usePageActions'
import { pageSource } from 'features/source'
import { updateTaskProjects, updateTaskTags } from 'logic/batchActions'
import { hideBatchActions as _hideBatchActions, showBatchActions, updateBatchActions } from 'store/batchActions/actions'
import { updateItems } from 'store/items/actions'
import { getScheduledGroup, groupPositionMap, sortListByTodayRank } from 'logic/today'
import { TaskObjectGroups } from 'logic/today/models'
import { launchUndoToast } from 'store/undoToast/actions'

export const InProgressSprintLineDroppableId = 'InProgressSprintLineDroppable'

export default function wrapper(Component) {
  function TodayPageContainer() {
    const {
      cancelCalendarTaskAction,
      cancelTaskAction,
      clearLocalTaskState,
      creatingCalendarTask,
      creatingTask,
      editingCalendarTask,
      editingTask,
      getDroppableId,
      ignoreOutsideClicks,
      isCreatingInlineTask,
      isDragging,
      keepCreatingTasks,
      onCreateCalendarTask,
      onDragEnd,
      onDragStart,
      onDeleteCalendarTaskCallback,
      onSaveCalendarTask,
      onTaskEditStart,
      onTogglePin,
      onTogglePinFromCalendarPanel,
      setIgnoreOutsideClicks,
      showCalendar,
      sprintComposerProps,
      startCalendarTaskAction,
      startInlineTaskCreation,
    } = useCalendarPanelContext()

    const dispatch = useDispatch()
    const {
      addTasksToSprint,
      allItems,
      archiveTask,
      completeTask,
      completeTaskFromFS,
      completeTasks,
      createInlineTask,
      createSprint,
      deleteItems,
      deleteSprint,
      deletionPopup,
      editTasks,
      endSprint,
      findItemById,
      getFocusedTaskId,
      isTaskCreationAlertShown,
      onClickDelete,
      onClickDeleteFocusSession,
      onClickFocusSession,
      onClickOutsideSprint,
      onClickSprint,
      onTaskDroppedInSprint,
      rescheduleItems,
      popShortcutsGroup,
      pushShortcutsGroup,
      saveTask,
      sprintDeletePopup,
      sprints,
      startFsAndCreateTask,
      uncompleteTask,
      updateFocusSession,
    } = usePageActions()
    const session = useSelector((state) => state.session)

    const [showBatchSelectShortcut, setShowBatchSelectShortCut] = useState(false)

    const itemsData = useMemo(() => {
      const today = new Date()
      const pageGroups = initGroupList(TaskObjectGroups, [])
      const totalSelectableItemsByGroup = initGroupList(TaskObjectGroups, 0)
      let totalSelectableItems = 0
      let pastItems = 0

      const pageItems = Object.keys(allItems).reduce((items, id) => {
        const item = allItems[id]

        if (!item || item.archived || (utils.datetime.isAfter(item.when?.date, today) && !item.completionTime))
          return items

        const group = getScheduledGroup(item)
        if (!group) return items
        if (item.type === models.item.type.SPRINT && utils.sprint.isPast(item)) pastItems++
        if (item.type === models.item.type.TASK && utils.task.isPast(item)) pastItems++
        items[group].push(item)
        totalSelectableItems++
        totalSelectableItemsByGroup[group]++

        if (item.tasks?.length) {
          totalSelectableItems += item.tasks.length
          totalSelectableItemsByGroup[group] += item.tasks.length
        }

        return items
      }, pageGroups)

      pageItems[TaskObjectGroups.PINNED] = utils.list.sortListByScheduledTime(pageItems[TaskObjectGroups.PINNED])
      pageItems[TaskObjectGroups.OTHER] = sortListByTodayRank(pageItems[TaskObjectGroups.OTHER])
      pageItems[TaskObjectGroups.COMPLETED] = utils.task.sortByCompletedAndCreationTimeAndCompletionTime(
        pageItems[TaskObjectGroups.COMPLETED]
      )

      setShowBatchSelectShortCut(pastItems >= 3)

      return { pageItems, totalSelectableItems, totalSelectableItemsByGroup }
    }, [allItems])

    let { pageItems, totalSelectableItems, totalSelectableItemsByGroup } = itemsData

    const inProgressSprints = useMemo(() => utils.sprint.getSprintsInProgress(sprints), [sprints])

    const completeBatch = useCallback(() => {
      deletionPopup(
        {
          cancelLabel: translations.batchActions.complete.cancel,
          confirmLabel: translations.batchActions.complete.confirm,
          text: translations.batchActions.complete.prompt,
          title: translations.batchActions.complete.prompt,
        },
        {
          onConfirmed: () => {
            const selectedItems = Object.keys(selectedItemsRef.current)
            completeTasks(selectedItems)
            mixpanelApi.track(
              { event: mixpanel.performedBatchActionEvent },
              {
                actionType: batchActionTypes.COMPLETE,
                batchSource: '',
                pageSource: pageSource.todayView,
                selectedItems: selectedItems.length,
              }
            )
          },
        }
      )
    }, [completeTasks, deletionPopup])

    const deleteBatch = useCallback(() => {
      deletionPopup(
        {
          cancelLabel: translations.batchActions.delete.cancel,
          confirmLabel: translations.batchActions.delete.confirm,
          text: translations.batchActions.delete.prompt,
          title: translations.batchActions.delete.prompt,
        },
        {
          onConfirmed: () => {
            const selectedItems = Object.keys(selectedItemsRef.current)
            deleteItems(selectedItems)
            mixpanelApi.track(
              { event: mixpanel.performedBatchActionEvent },
              {
                actionType: batchActionTypes.DELETE,
                batchSource: '',
                pageSource: pageSource.todayView,
                selectedItems: selectedItems.length,
              }
            )
          },
        }
      )
    }, [deleteItems, deletionPopup])

    const addBatchToFocusBlock = useCallback(
      (sprint) => {
        const targetSprintGroup = getScheduledGroup(sprint)
        let pageItem = null

        if (targetSprintGroup) {
          pageItem = pageItems[targetSprintGroup].find((i) => i.id === sprint.id)
        }

        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend, undoAction } = addTasksToSprint(sprint.id, selectedItems)

        if (!pageItem) {
          dispatch(launchUndoToast({ action: saveToBackend, undoAction }))
          return
        }

        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.ADD_TO_FB,
            batchSource: '',
            pageSource: pageSource.todayView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [addTasksToSprint, dispatch, pageItems]
    )

    const setProjectsToBatch = useCallback(
      (projects, indeterminateIds) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = editTasks(selectedItems, (t) => updateTaskProjects(t, projects, indeterminateIds))
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SELECT_PROJECT,
            batchSource: '',
            pageSource: pageSource.todayView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [editTasks]
    )

    const setDateToBatch = useCallback(
      (newDate) => {
        const today = moment()
        const isSameDay = moment(newDate).isSame(today, 'day')
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend, undoAction } = rescheduleItems(selectedItems, newDate)

        const saveAndTrackChanges = () => {
          saveToBackend()
          mixpanelApi.track(
            { event: mixpanel.performedBatchActionEvent },
            {
              actionType: batchActionTypes.SCHEDULE,
              batchSource: '',
              pageSource: pageSource.todayView,
              selectedItems: selectedItems.length,
            }
          )
        }

        if (!isSameDay) {
          dispatch(launchUndoToast({ action: saveAndTrackChanges, undoAction }))
        } else {
          saveAndTrackChanges()
        }
      },
      [dispatch, rescheduleItems]
    )

    const setTagsToBatch = useCallback(
      (tags, indeterminateIds) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = editTasks(selectedItems, (t) => updateTaskTags(t, tags, indeterminateIds))
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SELECT_TAG,
            batchSource: '',
            pageSource: pageSource.todayView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [editTasks]
    )

    const launchBatchActions = useCallback(() => {
      dispatch(
        showBatchActions({
          onComplete: completeBatch,
          onDelete: deleteBatch,
          onFocusBlockSelected: addBatchToFocusBlock,
          onProjectsSelected: setProjectsToBatch,
          onScheduleSelected: setDateToBatch,
          onTagsSelected: setTagsToBatch,
        })
      )
    }, [addBatchToFocusBlock, completeBatch, deleteBatch, dispatch, setDateToBatch, setProjectsToBatch, setTagsToBatch])

    const {
      onSelectItem,
      onToggleAllItemsSelection,
      onToggleItemsSelectionByGroups,
      selectedItems,
      selectMode,
    } = useBatchSelectionHandler({
      findItemById,
      getItemGroup: getScheduledGroup,
      pageGroupsOrder: groupPositionMap,
      pageItemsByGroup: pageItems,
      startBatchActions: launchBatchActions,
    })

    const selectedItemsRef = useRef(selectedItems)
    selectedItemsRef.current = selectedItems

    const totalSelectedItemsData = useMemo(() => {
      const countList = initGroupList(TaskObjectGroups, 0)
      let totalSelectedItems = 0
      let totalSelectedSprints = 0
      let totalSelectedTasks = 0

      Object.keys(selectedItems).forEach((id) => {
        const group = selectedItems[id]
        const item = findItemById(id)

        if (!item) return

        if (item.type === models.item.type.SPRINT) {
          totalSelectedSprints += 1
        } else {
          totalSelectedTasks += 1
        }

        countList[group] += 1
        totalSelectedItems += 1
      })

      return { totalSelectedItems, totalSelectedItemsByGroup: countList, totalSelectedSprints, totalSelectedTasks }
    }, [findItemById, selectedItems])

    const onCreateInlineTask = useCallback(
      async (task, { componentSource = 'inlineAddTask', tmpId, dontShowCreationAlert } = {}) => {
        const response = await createInlineTask({
          context: { componentSource, pageSource: pageSource.todayView },
          dontShowCreationAlert,
          task,
          tmpId,
        })

        return response
      },
      [createInlineTask]
    )

    const onCreateInlineTaskFromSprint = useCallback(
      async (task) => {
        if (!task.sprintInfo) return

        await createInlineTask({
          context: { componentSource: 'sprint', pageSource: pageSource.todayView },
          dontShowCreationAlert: true,
          task,
        })
      },
      [createInlineTask]
    )

    const handleStartFsAndCreateTask = useCallback(
      async (taskData, componentSource) => {
        await startFsAndCreateTask({ context: { componentSource, pageSource: pageSource.todayView }, taskData })
      },
      [startFsAndCreateTask]
    )

    const onComplete = useCallback(
      async ({ id, value }) => {
        if (value) {
          await completeTask({ id })
        } else {
          await uncompleteTask(id)
        }
      },
      [completeTask, uncompleteTask]
    )

    const onClickDeleteSprint = useCallback(
      (sprint) => {
        sprintDeletePopup(sprint, {
          onConfirmed: (recurrenceOption) => {
            deleteSprint(sprint.id, recurrenceOption)
          },
        })
      },
      [deleteSprint, sprintDeletePopup]
    )

    const onCreateSprint = useCallback(
      async (sprint, callback) => {
        const response = await createSprint(sprint)
        callback?.(response)
        return response
      },
      [createSprint]
    )

    const onRemoveFromSprint = useCallback(
      (task) => {
        const updatedTask = utils.task.computeTaskOnChange(task, {
          paramName: 'sprintInfo',
          value: null,
        })

        saveTask(updatedTask)
      },
      [saveTask]
    )

    const onDropTaskInSprint = useCallback(
      async ({ destinationIndex, itemId, sprintId }) => {
        if (!sprintId || !itemId) return

        await onTaskDroppedInSprint({
          destinationIndex,
          sprintId,
          taskId: itemId,
        })
      },
      [onTaskDroppedInSprint]
    )

    const onDropInOtherSection = useCallback(
      async ({ destinationIndex, destinationGroup, itemId, sourceData }) => {
        if (destinationGroup === TaskObjectGroups.PINNED) return

        const item = findItemById(itemId)

        if (!item) return

        const { extraParams, group: sourceGroup } = sourceData

        const rank = destinationIndex + 1
        const updatedItem = produce(item, (draft) => {
          draft.when.date = moment().format('YYYY-MM-DD')
          draft.todaySectionRank = rank

          if (extraParams?.sprintId) {
            delete draft.sprintInfo
          }
        })

        let updatedItems = [updatedItem]

        if (sourceGroup === TaskObjectGroups.OTHER) {
          updatedItems = pageItems[sourceGroup].filter((sectionItem) => sectionItem.id !== updatedItem.id)
          updatedItems.splice(destinationIndex, 0, updatedItem)
          updatedItems = updatedItems.map((sectionItem, index) =>
            produce(sectionItem, (draft) => {
              draft.todaySectionRank = index + 1
            })
          )
        }

        dispatch(updateItems(updatedItems))

        await todayviewApi.dragAndDropTasksInTodaySection({
          taskId: updatedItem.id,
          toRank: rank,
          date: updatedItem.when.date,
          inTodaySection: true,
        })
      },
      [dispatch, findItemById, pageItems]
    )

    const onDrop = useCallback(
      (draggableItem) => {
        if (!draggableItem.source || !draggableItem.destination) return

        const { id: draggableItemId, type: draggableType } = JSON.parse(draggableItem.draggableId)
        const sourceData = getDroppableId(draggableItem.source.droppableId)
        const { extraParams: destinationExtraParams, group: destinationGroup } = getDroppableId(
          draggableItem.destination.droppableId
        )

        if (sourceData.extraParams?.id === InProgressSprintLineDroppableId) return

        if (draggableType !== models.item.type.SPRINT && destinationExtraParams?.sprintId) {
          onDropTaskInSprint({
            destinationIndex: draggableItem.destination.index,
            itemId: draggableItemId,
            sprintId: destinationExtraParams.sprintId,
          })
        } else if (destinationGroup) {
          onDropInOtherSection({
            destinationIndex: draggableItem.destination.index,
            destinationGroup,
            itemId: draggableItemId,
            sourceData,
          })
        }
      },
      [getDroppableId, onDropInOtherSection, onDropTaskInSprint]
    )

    const onMoveToTopTodaySection = useCallback(
      async (taskId) => {
        const item = findItemById(taskId)

        if (!item) return

        const updatedItem = produce(item, (draft) => {
          draft.when.date = moment().format('YYYY-MM-DD')
          draft.todaySectionRank = 1
        })

        let updatedItems = pageItems[TaskObjectGroups.OTHER].filter((sectionItem) => sectionItem.id !== updatedItem.id)
        updatedItems.splice(0, 0, updatedItem)
        updatedItems = updatedItems.map((sectionItem, index) =>
          produce(sectionItem, (draft) => {
            draft.todaySectionRank = index + 1
          })
        )

        dispatch(updateItems(updatedItems))

        await todayviewApi.dragAndDropTasksInTodaySection({
          taskId: updatedItem.id,
          toRank: 1,
          date: updatedItem.when.date,
          inTodaySection: true,
        })
      },
      [dispatch, findItemById, pageItems]
    )

    const onArchiveAllCompletedToday = useCallback(() => {
      pageItems[TaskObjectGroups.COMPLETED].forEach((task) => {
        archiveTask(task)
      })
    }, [archiveTask, pageItems])

    const hideBatchActions = useCallback(() => {
      dispatch(_hideBatchActions())
    }, [dispatch])

    useEffect(() => {
      if (!selectMode) return

      dispatch(
        updateBatchActions({
          onComplete: completeBatch,
          onDelete: deleteBatch,
          onFocusBlockSelected: addBatchToFocusBlock,
          onProjectsSelected: setProjectsToBatch,
          onScheduleSelected: setDateToBatch,
          onTagsSelected: setTagsToBatch,
        })
      )
    }, [
      addBatchToFocusBlock,
      completeBatch,
      deleteBatch,
      dispatch,
      selectMode,
      setDateToBatch,
      setProjectsToBatch,
      setTagsToBatch,
    ])

    const toHideTaskId = getFocusedTaskId()

    return (
      <Component
        allItems={pageItems}
        cancelCalendarTaskAction={cancelCalendarTaskAction}
        cancelTaskAction={cancelTaskAction}
        clearLocalTaskState={clearLocalTaskState}
        creatingCalendarTask={creatingCalendarTask}
        creatingTask={creatingTask}
        editingCalendarTask={editingCalendarTask}
        editingTask={editingTask}
        hideBatchActions={hideBatchActions}
        ignoreOutsideClicks={ignoreOutsideClicks}
        inProgressSprints={inProgressSprints}
        isCreatingInlineTask={isCreatingInlineTask}
        isDragging={isDragging}
        isTaskCreationAlertShown={isTaskCreationAlertShown}
        keepCreatingTasks={keepCreatingTasks}
        onArchive={archiveTask}
        onArchiveAllCompletedToday={onArchiveAllCompletedToday}
        onClickDelete={onClickDelete}
        onClickDeleteFocusSession={onClickDeleteFocusSession}
        onClickDeleteSprint={onClickDeleteSprint}
        onClickFocusSession={onClickFocusSession}
        onClickOutsideSprint={onClickOutsideSprint}
        onClickSprint={onClickSprint}
        onComplete={onComplete}
        onCompleteFromFS={completeTaskFromFS}
        onCreateCalendarTask={onCreateCalendarTask}
        onCreateInlineTask={onCreateInlineTask}
        onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
        onCreateSprint={onCreateSprint}
        onDeleteCalendarTaskCallback={onDeleteCalendarTaskCallback}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onEndSprint={endSprint}
        onMoveToTopTodaySection={onMoveToTopTodaySection}
        onRemoveFromSprint={onRemoveFromSprint}
        onSave={saveTask}
        onSaveCalendarTask={onSaveCalendarTask}
        onSelectItem={onSelectItem}
        onTaskEditStart={onTaskEditStart}
        onToggleAllItemsSelection={onToggleAllItemsSelection}
        onToggleItemsSelectionByGroups={onToggleItemsSelectionByGroups}
        onTogglePin={onTogglePin}
        onTogglePinFromCalendarPanel={onTogglePinFromCalendarPanel}
        onUpdateFocusSession={updateFocusSession}
        popShortcutsGroup={popShortcutsGroup}
        pushShortcutsGroup={pushShortcutsGroup}
        selectedItems={selectedItems}
        selectMode={selectMode}
        session={session}
        setIgnoreOutsideClicks={setIgnoreOutsideClicks}
        showCalendar={showCalendar}
        showBatchSelectShortcut={showBatchSelectShortcut}
        sprintComposerProps={sprintComposerProps}
        startCalendarTaskAction={startCalendarTaskAction}
        startFsAndCreateTask={handleStartFsAndCreateTask}
        startInlineTaskCreation={startInlineTaskCreation}
        toHideTaskId={toHideTaskId}
        totalSelectableItems={totalSelectableItems}
        totalSelectableItemsByGroup={totalSelectableItemsByGroup}
        totalSelectedItemsData={totalSelectedItemsData}
      />
    )
  }

  return React.memo(withRouter(TodayPageContainer))
}
